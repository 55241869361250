import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import Loading from "../common/Loading";

const ChangePassword = ({loading , handleClose , passwordHandler}:{loading:any , handleClose:()=>void , passwordHandler:(a:any)=> void}) => {

    const initialState = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    }

    const [passwordFields, setPasswordFields] = useState(initialState)
    const [visiblePassword, setVisiblePassword] = useState({
        isCurrentPasswordVisible: false,
        isNewPasswordVisible: false,
        isConfirmPasswordVisible: false
    })
    const { isCurrentPasswordVisible, isNewPasswordVisible, isConfirmPasswordVisible } = visiblePassword;

    const handleChange = (event: any) => {
        const { value, name } = event.target;
        setPasswordFields({
            ...passwordFields, [name]: value
        })
    }

 
    const passwordVisibilityChange = (value: string) => {


        switch (value) {
            case "currentPassword":
                return setVisiblePassword({ ...visiblePassword, isCurrentPasswordVisible: !isCurrentPasswordVisible });
            case "newPassword":
                return setVisiblePassword({ ...visiblePassword, isNewPasswordVisible: !isNewPasswordVisible });
            case "confirmPassword":
                return setVisiblePassword({ ...visiblePassword, isConfirmPasswordVisible: !isConfirmPasswordVisible });
            default:
                return {
                    value
                };
        }
    }

    const handleSubmit =  () => {
        const { currentPassword, newPassword, confirmPassword } = passwordFields;
        if (!currentPassword || !newPassword || !confirmPassword) return toast.error("Please fill required fields")

        const strongPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;

        // if (!strongPasswordRegex.test(newPassword)) {
        //     return toast.error("New password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*).");
        // }

        if (newPassword !== confirmPassword) {
            return toast.error("New password and confirm password must match");
        }

        passwordHandler(passwordFields)
    }

  return (
    <div className="overlay">
    <div className="change-password-main">
            <div className="change-head">
                <h2>Change Password</h2>
                <button onClick={handleClose}><img src="images/times_icon.svg" alt="" /></button>
            </div>
            <div className="input-field">
                <label>Current Password</label>
                <div className="password">
                <input type={isCurrentPasswordVisible ? "text" : "password"} placeholder="**********" name="currentPassword" value={passwordFields.currentPassword} onChange={handleChange} />
                    <span onClick={() => passwordVisibilityChange("currentPassword")}>{isCurrentPasswordVisible ? <IoMdEye size={25} /> : <IoIosEyeOff size={25} />}</span>
                </div>
            </div>
            <div className="input-field">
                <label>New Password</label>
                <div className="password">
                <input type={isNewPasswordVisible ? "text" : "password"} placeholder="**********" name="newPassword" value={passwordFields.newPassword} onChange={handleChange} />
                <span onClick={() => passwordVisibilityChange("newPassword")}>{isNewPasswordVisible ? <IoMdEye size={25} /> : <IoIosEyeOff size={25} />}</span>
                </div>
            </div>
            <div className="input-field">
                <label>Confirm New Password</label>
                <div className="password">
                <input type={isConfirmPasswordVisible ? "text" : "password"} placeholder="**********" name="confirmPassword" value={passwordFields.confirmPassword} onChange={handleChange} />
                <span onClick={() => passwordVisibilityChange("confirmPassword")}>{isConfirmPasswordVisible ? <IoMdEye size={25} /> : <IoIosEyeOff size={25} />}</span>
                </div>
            </div>
            <div className="password-btn">
                <button className="cancle-btn" onClick={handleClose}>Cancel</button>
                <button className="Save-btn" onClick={handleSubmit} disabled={loading} > {loading ? <Loading/> :  "Save"}</button>
            </div>
        </div>
    </div>
  );
}

export default ChangePassword;
