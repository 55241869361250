import { Navigate } from 'react-router-dom';

import { useState } from 'react';
import { getRole, getToken } from './utils/common';
import WebSideMenu from './website/components/webSideMenu';

export function AuthenticatedApp({ children }: any) {

  const token = getToken()

  const [activeMenu, setActiveMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  const role = getRole()

  if (!token) return <Navigate to="/" />

  if (role === "sales_member" ) {
    return (
   

<div className="admin-dashboard">
                        <WebSideMenu activeMenu={activeMenu} setActiveMenu={setActiveMenu} isHovered={isHovered} setIsHovered={setIsHovered} />
                        <div className={`main-dashboard  ${!isHovered ? 'dashboard-active' : ''}  subadmin-dashboard ${activeMenu && "dashboard-active"} }`}>
                            {children}
                        </div>
                    </div>

    );

  } else {
    return <Navigate to="/" />
  }

}
