import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import PageLoading from '../components/common/PageLoading';
import Pagination from '../components/common/Pagination';
import { getAdminIncident, getBrandType, getCsvFile } from '../config/api';
import DataGrid from './DataGrid';

import moment from "moment-timezone";
import toast from 'react-hot-toast';
import { BsThreeDots } from "react-icons/bs";
import Illustration from '../components/common/Illustration';
import Loading from '../components/common/Loading';
import NewIncidents from '../website/components/NewIncidents';




const IncidentsList = () => {

  const [actionToggle, setActionToggle] = useState(false)
  const [editData, setEditData] = useState(null)
  const [csvLoading, setCsvLoading] = useState(false)
  const [data, setData] = useState<any>(null)

  const formatDate = (dateString: any) => {
   
    const format = 'DD-MM-YYYYTHH:mm:ss.SSSSZ'; // Adjust if the format is slightly different
  
    // Parse the date string with the specified format
    const momentDate = moment(dateString, format, true); 
    if (!momentDate.isValid()) {
      return 'Invalid Date'; // Fallback for invalid dates
    }
  
    return momentDate.tz('America/New_York').format('MM/DD/YYYY');
  };
  
  const formatTime = (dateString: string) => {
    const format = 'DD-MM-YYYYTHH:mm:ss.SSSSZ'; // Adjust if the format is slightly different
  
    const momentDate = moment(dateString, format, true);
  
    if (!momentDate.isValid()) {
      return 'Invalid Time'; // Fallback for invalid dates
    }
  
    return momentDate.tz('America/New_York').format('h:mm A'); // Format for time only
  };


  const columns = [
    { defaultFlex: 1, name: 'inc', header: 'Incident ID', minWidth: 150 },
    {
      defaultFlex: 2, name: 'date', header: 'Date', minWidth: 150, render: ({ data }: any) => (
        <>
          {
            
            formatDate(data?.date)
          } <br />
          {
            formatTime(data?.date)
          }

        </>
      )
    },

    { defaultFlex: 3, name: 'type', header: 'Type', minWidth: 150 },
    { defaultFlex: 4, name: 'shortDesc', header: 'Short Desc.', minWidth: 150 },
    { defaultFlex: 5, name: 'brand', header: 'Brand', minWidth: 150 },
    { defaultFlex: 6, name: 'phone', header: 'Phone', minWidth: 150 },
    { defaultFlex: 7, name: 'esn', header: 'ESN', minWidth: 150 },
    { defaultFlex: 8, name: 'sim', header: 'SIM', minWidth: 150 },
    { defaultFlex: 9, name: 'store', header: 'Store', minWidth: 150 },
    { defaultFlex: 10, name: 'submittedBy', header: 'Submitted By', minWidth: 150 },
    { defaultFlex: 10, name: 'zipCode', header: 'Zip Code', minWidth: 150 },
    { defaultFlex: 11, name: 'position', header: 'Position', minWidth: 150 },
    {
      defaultFlex: 12,
      name: 'longDesc',
      header: 'Action',
      minWidth: 100,
      render: ({ data:rowData }: any) => {
        return (
          <>
            <button className="action_btn" style={{ backgroundColor: "transparent" }} onClick={() => handleEyeButtonClick(rowData)}><BsThreeDots /></button>

          
                <div className={`action-main action-main-new`} style={{ zIndex: 9999, position: "absolute" }}>
                  <ul>
                    <li
                      onClick={() => navigate(`/incident-view/${rowData?.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <a className="edit-btn">
                        <span><img src="images/action_eye.svg" alt="" /></span>View Info
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setEditData(rowData)
                        setNewIncident(true)
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <a className="block-btn">
                        <span><img src="images/permission_icon.svg" alt="" /></span>Edit
                      </a>
                    </li>
                  </ul>
                </div>
            


          </>
        )



      }

    },

   
    
     
  ].map(col => ({ ...col, visible: true }));


  const [params, setParams] = useState({
    page: 1,
    perPage: 10,
  })
  const [type, setType] = useState("")
  const [loading, setLoading] = useState(false)
  const [brand, setBrand] = useState("")
  const [search, setSearch] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [role, setRole] = useState("")
  const [toDate, setToDate] = useState("")
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });

  const navigate = useNavigate()
  const [dynamicTypeOrBrand, setDynamicTypeOrBrand] = useState<any>([])
  const [typeItems, setTypeItems] = useState([])
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [newIncident, setNewIncident] = useState(false)

  const handleEyeButtonClick = (data: any) => {
    // setActionToggle(!actionToggle)
    setActionToggle(true)
    

  };




  const toggleColumnSelector = () => {
    setShowColumnSelector(!showColumnSelector);
  };

  const handleColumnChange = (column: any, isVisible: any) => {
    setVisibleColumns(prevColumns =>
      prevColumns.map(col =>
        col.name === column.name ? { ...col, visible: isVisible } : col
      )
    );
  };

  const roles = [
    { title: "Dealer/Owner", abbreviation: "DLR" },
    { title: "District Manager", abbreviation: "DM" },
    { title: "Retail Store Manager", abbreviation: "RSM" },
    { title: "Retail Store Assistant Manager", abbreviation: "RAM" },
    { title: "Retail Store Associate", abbreviation: "RSA" },
    { title: "Operations", abbreviation: "OPR" }
  ];


  const handleDateChange = (event: any) => {
    const { name, value } = event.target;
    setDateRange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  useEffect(() => {
    (async () => {
      try {
        let res: any = await getBrandType()
        if (res?.status) {
          setTypeItems(res?.data?.incidentTypes)
          setDynamicTypeOrBrand(res?.data?.brands)
        }


      } catch (error) {
        console.log("errror", error)

      }

    })()

  }, [])


  const getData = async () => {
    try {
      setLoading(true)
      const from = dateRange?.fromDate ? moment(dateRange.fromDate).format("DD-MM-YYYY") : "";
      const to = dateRange?.toDate ? moment(dateRange.toDate).format("DD-MM-YYYY") : "";
      let res: any = await getAdminIncident(params.page, params.perPage, search, role, type, brand, zipCode, from, to)
      if (res?.status) {
        setData(res?.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error, "errorr")
    }
  }
  useEffect(() => {
    getData()


  }, [params, role, search, type, brand, zipCode, dateRange])




  //* pagination page handler 
  const pageHandler = (e: any) => {
    const page = e.selected + 1
    setParams({ ...params, page })
  }

  const handlePageSelect = (value: number) => {
    setParams({ ...params, perPage: value })

  }
  const closeHandle = () => {
    setNewIncident(false)
    setEditData(null)
    getData()
  }

  const exportAsCSV = async () => {
    try {
      setCsvLoading(true)
      let response:any = await getCsvFile()
      if(response?.status){
        const anchor = document.createElement("a");
        anchor.href = response?.exportedFile;
        anchor.download = response?.exportedFile
        anchor.click();
        anchor.remove();
        setCsvLoading(false)
  
        toast.success(response?.message);
      }
     
    } catch (error:any) {
      setCsvLoading(false)
      toast.error(error?.message);
    }
  };

  return (
    <>
      <Header title={'Incidents'} backButton={editData || null} backHandle={() => {
        setNewIncident(false)
        setEditData(null)
      }} admin={true}
      />
      {newIncident ? <NewIncidents closeHandle={closeHandle} editData={editData} /> : <div className="loaction-list-main">
        <div className="loaction-list-head">
          <div className="search-bar">
            <input type="search" value={search} onChange={(e: any) => setSearch(e.target.value)} placeholder="Search by name" />
            <img src="images/search_icon.svg" alt="" />
          </div>
          <div>
            <button className='export-btn' onClick={exportAsCSV} disabled={csvLoading}>{csvLoading ? <Loading /> : "Export"}</button>
          </div>
        </div>
        <div className="filter-bar-main">
          <ul>
            <li>
              <select value={role} onChange={(e: any) => setRole(e.target.value)}>
                <option value="">Select</option>
                {
                  roles?.map((value) => {
                    return (
                      <>
                        <option value={value?.title}>{value?.title}</option>
                      </>
                    )
                  })
                }

              </select>
            </li>
            <li>
              <select value={type}
                onChange={(e) => setType(e.target.value)}>
                <option value="">Select</option>
                {
                  typeItems?.map((value: string) => {

                    return <option value={value} >{value}</option>
                  })
                }

              </select>
            </li>
            <li>
              <select value={brand || "Select"} onChange={(event: any) => setBrand(event.target.value)} >
                <option value="">Select</option>
                {
                  dynamicTypeOrBrand?.map((value: string) => {
                    return <option value={value} >{value}</option>
                  })
                }
              </select>
            </li>
            <li>
              <input type="text" placeholder='zip code' value={zipCode} onChange={(event) => setZipCode(event.target.value)} />
            </li>
            <li>
              <input
                type="date" name="fromDate"
                value={dateRange.fromDate}
                onChange={handleDateChange}
              />
            </li>
            <li>
              <input type="date"
                placeholder="dgdgsfsf"
                name="toDate"
                value={dateRange.toDate}
                onChange={handleDateChange}

              />
            </li>
          </ul>
          <div className="filter-btn-group">
            {/* <div>
                  <span>Sort By</span>
                  <button>A to Z</button>
              </div> */}
            {
              data?.total > 0 && <div className='main-column'>
                <button className='man' onClick={toggleColumnSelector}>
                  {showColumnSelector ? 'Hide' : 'Show'} Columns
                  {
                    showColumnSelector && (
                      <div className="column-selector">
                        {columns.map(column => (
                          <div key={column.name}>
                            <input
                              type="checkbox"
                              checked={visibleColumns.some(col => col.name === column.name && col.visible !== false)}
                              onChange={e => handleColumnChange(column, e.target.checked)}
                            />
                            <label>{column.header}</label>
                          </div>
                        ))}
                      </div>
                    )
                  }
                </button>

              </div>
            }

          </div>
        </div>

        {
          loading ? <PageLoading /> : data?.total == 0 ? <Illustration imgLink={"/images/noIncident.svg"} title="No Incidents Yet!" /> : <DataGrid dataSource={data?.incidents} visibleColumns={visibleColumns} />
        }


      </div>}

      {
        data?.total > 0 && !editData && <Pagination paramsData={params} total={data?.total} pageCount={data?.totalPages} handlePageChange={pageHandler} handlePageSelect={handlePageSelect} increasePageSize={true} />
      }
    </>
  );
}

export default IncidentsList;
