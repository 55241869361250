import { useEffect, useRef, useState } from "react";
import { resendOtp, verifyEmail, verifyOtp } from "../../config/api";
import toast from "react-hot-toast";
import Loading from "../common/Loading";
import { Loader } from 'rsuite';

const VerifyOtp = ({isLoading ,  email , backHandler , handleForgot , verifySubmit}:{resendOtpLoading:boolean  , isLoading:boolean , email:string , backHandler:(a:string)=>void , handleForgot:(a:string)=>void , verifySubmit:any}) => {
  const [otpValue, setOtpValue] = useState(new Array(6).fill(""));
  const inputRef = useRef<Array<HTMLInputElement | null>>([]);
  // const [loading , setIsLoading] = useState(false)
  const [resendOtpLoading , setResendOtpLoading] = useState(false)


  useEffect(() => {
    if (inputRef.current[0]) {
      inputRef.current[0].focus();
    }
  }, []);

  // Otp handler
  function otpHandler(value: string, i: number) {
    const adminInput = value.substring(value.length - 1);
    setOtpValue((prevArray) => {
      const newArray = [...prevArray];
      newArray[i] = adminInput;
      return newArray;
    });

    // move focus to the next input
    if (
      inputRef.current &&
      i < otpValue.length - 1 &&
      value !== "" &&
      inputRef.current[i + 1]
    ) {
      inputRef.current[i + 1]!.focus();
    }
  }

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    i: number
  ) => {
    if (e.key == "Backspace") {
      // move focus to the previous  input
      if (!otpValue[i] && i > 0) {
        inputRef.current[i - 1]!.focus();
      }
    }
  };
  const otp = otpValue.join("")
    const submitHandler = async ()=>{
      if(otp.length < 6) return

     verifySubmit(otp)
    
    }

    const resendOtpHandle = async () => {
      if (!email || resendOtpLoading) return

      try {
        setResendOtpLoading(true)
        let res:any = await resendOtp({ email })
        if(res?.status){
          setResendOtpLoading(false)
          toast.success(res?.message)
        }
        
  
      } catch (error:any) {
        toast.error(error?.message)
        setResendOtpLoading(false)
        console.log("error")
      }
  
    }



  return (
    <>
       <div className="login-back-btn" onClick={()=>backHandler('ConfirmEmail')}>
              <a ><img src="images/left_arrow.svg" alt="" /></a>
            </div>
            <div className="logo">
              <a href="#"><img src="images/Logo.png" alt="Logo" /></a>
            </div>
            <div className="login-input-details">
              <h2>Verifying Email</h2>
              <p>Please enter the OTP sent on your email</p>
              <div className="otp-input">
              {otpValue?.map((_item: string, i: number) => (
            <input
              key={i}
              value={otpValue[i]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                otpHandler(event.target.value, i)
              }
              type="number"
              ref={(input) => {
                inputRef.current[i] = input;
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyPress(e, i)
              }
            />
          ))}
              </div>
              <div className="login-btn">
                <button onClick={submitHandler} disabled={isLoading || otp.length < 6 || resendOtpLoading }> {isLoading ? <Loading/> :  "SUBMIT"}</button>
              </div>
              <div className="resend-otp">
                <p>Didn't receive the email? <a onClick={resendOtpHandle} className="transparent_text">{resendOtpLoading ? <Loader content="Loading..." /> : " Click to resend" } </a></p>
              </div>
            </div>
    </>
  );
}

export default VerifyOtp;
