import React, { useState } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '../utils/common';

const gridStyle = { minHeight: 550, width: "100vw" };

const DataGrid = ({ dataSource , visibleColumns }:any) => {

    
    return (
        <>
            {dataSource && (
                <>
                <ReactDataGrid
                    idProperty="id"
                    columns={visibleColumns.filter((col:any) => col.visible !== false)}
                    dataSource={dataSource}
                    style={gridStyle}
                />
               
                </>
            )}
        </>
    );
};

export default DataGrid;
