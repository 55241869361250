import { useState, useEffect } from 'react';
import { addPermission } from '../../config/api';
import toast from 'react-hot-toast';

const IncidentExportSelect = ({ value , setData}: any) => {
  const [incidentExport, setIncidentExport] = useState<string>("");

  // Initialize the incidentExport state with value from props
  useEffect(() => {
    if (value?.permissions?.incidentExport) {
      setIncidentExport(value.permissions.incidentExport);
    }
  }, [value]);

  const handleRadioChange = (newValue: string) => {
    // Update the state with the new value
    // setIncidentExport(newValue);

    // Optionally send the updated value to the API
    sendIncidentExport(newValue);
  };

  const sendIncidentExport = async (newValue: string) => {
    const formData = new FormData()
    formData.append("firstName" , value.firstName)
    formData.append("lastName" , value.lastName)
    formData.append("phoneNumber" , value.number)
    formData.append("email" , value.email)
    formData.append("position" , value.position)
    formData.append("tspId" , value.tspId)
    formData.append("storeName" , value?.storeName)
    formData.append("positionShortName" , value?.positionShortName)
    formData.append("storeZipCode" , value.zipCode)
    formData.append("incidentExport" , newValue)

    if(value.permissions.calculator?.calculator?.toLowerCase() == "yes"){
        formData.append("calculator" , "Yes")
        formData.append("sheets" , JSON.stringify(value.permissions.calculator.sheets))
    }else{
        formData.append("sheets" , JSON.stringify([]))
        formData.append("calculator" , "No")
    }

    try {
        const res:any = await addPermission(formData , value?.id)
        setData((prev: any) => {
          const websiteUsers = prev?.websiteUsers;
          const users = websiteUsers?.map((item: any) => {
            if (item?.id === value?.id) {
              return {
                ...item,
                permissions: {
                  ...item.permissions,
                  "incidentExport":newValue
                },
              };
            }
            return item;
          });
        
          // Return the new state with the updated users array
          return {
            ...prev,
            websiteUsers: users,
          };
        });
    } catch (error:any) {
      toast.error(error?.message)
      console.log('Error updating incident export:', error);
    }
  };

  return (
    <td className="table-col">
      <ul className="sheet-select">
        <li>
          <input 
            type="radio" 
            checked={incidentExport === "Yes"} 
            onChange={() => handleRadioChange("Yes")} 
          />
          <label>Yes</label>
        </li>
        <li>
          <input 
            type="radio" 
            checked={incidentExport === "No"} 
            onChange={() => handleRadioChange("No")} 
          />
          <label>No</label>
        </li>
      </ul>
    </td>
  );
};

export default IncidentExportSelect;
