import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../context/AppProvider';
import { getProfileData } from '../../config/api';
import { CSVLink } from "react-csv";
import ImageModal from '../../components/common/ImageModal';
// import moment from 'moment';
import moment from "moment-timezone"
import { IoMdDownload } from "react-icons/io";
import { maskNumber } from '../../utils/common';

const IncidentView = ({ viewData }: any) => {
  const { profileData } = useContext(AppContext)

  const [permission, setPermission] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>("")

  const openModal = (img: string) => {
    setModalOpen(true);
    setSelectedImage(img);

  }




  const closeModal = () => {
    setSelectedImage("")
    setModalOpen(false);
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const res: any = await getProfileData()
        if (res?.status) {
          if (res?.data?.permissions?.incidentExport == "Yes") {
            setPermission(true)
          }

        }

      } catch (error) {
        console.log("error", error)
      }
    }
    getData()

  }, [])


  const formatDate = (dateString: any) => {

    const format = 'DD-MM-YYYYTHH:mm:ss.SSSSZ'; // Adjust if the format is slightly different

    // Parse the date string with the specified format
    const momentDate = moment(dateString, format, true);
    if (!momentDate.isValid()) {
      return 'Invalid Date'; // Fallback for invalid dates
    }

    return momentDate.tz('America/New_York').format('MM/DD/YYYY');
  };

  const formatTime = (dateString: string) => {
    const format = 'DD-MM-YYYYTHH:mm:ss.SSSSZ'; // Adjust if the format is slightly different

    const momentDate = moment(dateString, format, true);

    if (!momentDate.isValid()) {
      return 'Invalid Time'; // Fallback for invalid dates
    }

    return momentDate.tz('America/New_York').format('h:mm A'); // Format for time only
  };

  const isUserIncident = (value: any) => {
    if (profileData?.role == "admin") return value
    const isIncident = profileData?.id === viewData?.userId
    if (isIncident) {
      return value
    } else {
      return maskNumber(viewData?.phone)
    }
  }


  const headers = [
    { label: "Type", key: "type" },
    { label: "Brand", key: "brand" },
    { label: "Phone", key: "phone" },
    { label: "Esn", key: "esn" },
    { label: "Sim", key: "sim" },
    { label: "Short Description", key: "shortDesc" },
    { label: "Long Description", key: "longDesc" },
    { label: "Date", key: "date" },
    { label: "Store", key: "store" },
    { label: "Zip Code", key: "zipCode" },
    { label: "Paste Screenshots", key: "pasteScreenshots" },
    { label: "Upload Media", key: "uploadMedia" },
  ];
  const data = [{ ...viewData, "date": formatDate(viewData?.date), "sim": isUserIncident(viewData?.sim), "phone": isUserIncident(viewData?.phone), "esn": isUserIncident(viewData?.esn), "store": isUserIncident(viewData?.store) }];



  return (
    <>
      <ImageModal imageUrl={selectedImage} isOpen={isModalOpen} onRequestClose={closeModal} />
      <div className="loaction-list-main">
        <div className="issue-main">
          <div className="head" style={{ justifyContent: "right", boxShadow: "none", padding: "25px 35px 0" }}>
            {permission && <CSVLink data={data} filename={"Incident.csv"} headers={headers}><button><IoMdDownload size={30} color='#FE0000' /></button> </CSVLink>}
          </div>

          <div className="issue-details-main">
            <div className="input-group">
              <div className="input-field">
                <label >Type</label>
                <input type="text" disabled name="" id="" value={viewData?.type} />
              </div>
              <div className="input-field">
                <label >Brand</label>
                <input type="text" disabled id="" value={viewData?.brand} />
              </div>
              <div className="input-field">
                <label >Phone</label>
                <input type="text" disabled placeholder="111111111111" name={"phoneNumber"} value={isUserIncident(viewData?.phone)} id="" />
              </div>
              <div className="input-field">
                <label >ESN</label>
                <input type="text" disabled placeholder="111111111111" name="esn" value={isUserIncident(viewData?.esn)} />
              </div>
            </div>
            <div className="input-group">
              <div className="input-field">
                <label >SIM</label>
                <input type="text" disabled placeholder="111111111111" name='sim' value={isUserIncident(viewData?.sim)} />

              </div>

              <div className="input-field input-short">
                <label >Short Description</label>
                <input type="text" disabled placeholder="Type Something...." name="shortDescription" value={viewData?.shortDesc} />
              </div>

            </div>
            <div className="input-group">
              <div className="input-field">
                <label >Date</label>
                <input type="text" disabled value={formatDate(viewData?.date)} />
              </div>
              <div className="input-field">
                <label >Time</label>
                <input type="text" disabled value={formatTime(viewData?.date)} name="" id="" />
              </div>
              <div className="input-field">
                <label >Store</label>
                <input type="text" disabled name="" id="" value={isUserIncident(viewData?.store)} />
              </div>
              <div className="input-field">
                <label >Zipcode</label>
                <input type="text" disabled value={viewData?.zipCode} name="" id="" />
              </div>
              <div className="input-field">
                <label >Submitted By</label>
                <div style={{ position: "relative" }}>
                  <input type="text" disabled value={`${viewData?.submittedBy}`} name="" id="" />
                  <span>{viewData?.positionShortName}</span>
                </div>
              </div>
            </div>
            <div className="input-group">
              <div className="input-field input-text">
                <label >Long Description / Details</label>
                <textarea name="" disabled id="" value={viewData?.longDesc
                } />
              </div>
            </div>
            <div className="uploaded-imgs uploaded-imgs-view">
              <div className="images">
                {viewData?.uploadMedia && <label >Uploaded Image / Video</label>}
                {
                  viewData?.uploadMedia?.map((value: any, index: any) => {
                    const isVideo = value.endsWith('.mp4') || value.endsWith('.mov') || value.endsWith('.wmv') || value.endsWith('.avi');

                    return (
                      <React.Fragment key={index}>
                        {isVideo ? (
                          <video controls playsInline={true}>
                            <source src={value} />
                          </video>
                        ) : (
                          <img src={value} onClick={() => openModal(value)} height={100} width={200} alt="upload" />
                        )}
                      </React.Fragment>
                    );
                  })}

              </div>
              <div className="images">
                {viewData?.pasteScreenshots && <label >Uploaded Image</label>}
                {
                  viewData?.pasteScreenshots?.map((item: string) => {
                    return (
                      <>
                        <span><img src={item} alt="" onClick={() => openModal(item)} /></span>

                      </>
                    )
                  })

                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IncidentView;
