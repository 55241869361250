import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { createIncident, getBrandType, grammarPrompt, updateIncident } from "../../config/api";
import Loading from "../../components/common/Loading";
import CustomTextArea from "./CustomTextArea";

import grammarIcon from "../../assets/svg/grammaricon.svg"


const NewIncidents = ({ closeHandle, editData }: { closeHandle: any, editData: any }) => {
  const [data, setData] = useState({
    phoneNumber: "",
    esn: "",
    sim: "",
    shortDescription: "",
    // longDescription: "",
  });

  const [incidentType, setIncidentType] = useState("");
  const [type, setType] = useState(false);
  const [brandToggle, setBrandToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [grammarLoading, setGrammarLoading] = useState(false);
  const [uploadMedia, setUploadMedia] = useState([null, null]);
  const [screenshot, setScreenshot] = useState(null);
  const [screenshot2, setScreenshot2] = useState(null);
  const [brand, setBrand] = useState("");
  const [brandType, setBrandType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [typeDropdownInput, setTypeDropDownInput] = useState(true);
  const [typeBrandInput, setTypeInput] = useState(true);
  const [longDescription, setLongDescription] = useState("");

  const inputRef = useRef<any>(null);
  const inputRef2 = useRef<any>(null);
  const mediaRef = useRef<any>(null);
  const mediaRef2 = useRef<any>(null);

  const [dynamicTypeOrBrand, setDynamicTypeOrBrand] = useState<any>(null);
  const [typeItems, setTypeItems] = useState([]);
  

  const { phoneNumber, esn, sim, shortDescription } = data;

  useEffect(() => {
    function handleClickOutside(event: any) {
      const id = event.target.id;
      if (!id) {
        setType(false);
        setBrandToggle(false);
      }
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let res: any = await getBrandType();
        if (res?.status) {
          setTypeItems(res?.data?.incidentTypes);
          setDynamicTypeOrBrand(res?.data);
        }
      } catch (error) {
        console.log("errror", error);
      }
    })();
  }, []);


  useEffect(() => {
    if (editData ) {
      setUploadMedia(editData?.uploadMedia)
      setBrandType(editData?.type)
      setBrand(editData?.brand)
      setData({...data , "esn":editData?.esn , "phoneNumber":editData?.phone , "sim":editData?.sim ,"shortDescription": editData?.shortDesc})
      setLongDescription(editData?.longDesc)
    

      if(editData?.pasteScreenshots){
        if (editData?.pasteScreenshots?.length > 1) {
          setScreenshot(editData?.pasteScreenshots[0])
          setScreenshot2(editData?.pasteScreenshots[1])
        } else {
          setScreenshot(editData?.pasteScreenshots[0])
        }
      }
    }

  }, [editData])

 



  const handleTypeSearch = (event: any) => {
    const { value } = event.target;
    setSearchQuery(value);
    const actualValue = value.toLowerCase();

    const filterValue = dynamicTypeOrBrand?.incidentTypes?.filter(
      (item: any) => {
        const type = item.toLowerCase();
        return type.includes(actualValue);
      }
    );

    setTypeItems(filterValue);
  };

  const handleMedia = (event: any, index: any) => {
    const file = event.target?.files[0];
    if (file) {
      if (file.type.startsWith("video/")) {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
          if (video.duration > 30) {
            toast.error("Please upload a video of 30 seconds or less.");
            event.target.value = null; // Reset the input
          } else {
            let prevValue = [...uploadMedia];
            prevValue[index] = file;
            setUploadMedia(prevValue);
          }
        };
      } else {
        let prevValue = [...uploadMedia];
        prevValue[index] = file;
        setUploadMedia(prevValue);
      }
    }
  };
  //* profile change
  const handleImage = (event: any, index: number) => {
    const file = event.target?.files[0];
    if (file) {
      if (index == 0) {
        setScreenshot(file);
      } else {
        setScreenshot2(file);
      }
    }
  };

  const handleClickItems = (item: any) => {
    if (item === "Other") {
      setTypeInput(false);
      // Perform the external feature for "Other"
    } else {
      setBrand(item);
    }
    setBrandToggle(false);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "shortDescription") {
      if (value.length > 75) return;
    }

    if (name == "phoneNumber") {
      if (value.length > 10) return
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleTypeClick = () => {
    setType(!type);
  };
  const handleTypeBrandClick = () => {
    setBrandToggle(!brandToggle);
  };
  const handleBrandTypeClick = () => {
    setType(!type);
  };
  const handleClick = (item: any) => {
    if (item === "Other") {
      setTypeDropDownInput(false);
    } else {
      setBrandType(item);
    }
    setType(false);
  };

  const handleDrop = (event: any, value: string) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      if (value == "screenshot") {
        setScreenshot(file);
      }

      if (value == "screenshot2") {

      }


      if (value === "index0") {
        setUploadMedia((prev: any[]) => {
          let data = [...prev];
          data[0] = file;
          return data;
        });

      }
      if (value == "index1") {
        setUploadMedia((prev: any[]) => {
          let data = [...prev];
          data[1] = file;
          return data;
        });
      }

    } else {
      alert("Please drop an image file.");
    }
  };


  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleMediaTrash = (index: number) => {
    setUploadMedia((prev: any[]) => {
      let data = [...prev];
      data[index] = null;
      return data;
    });
  };

  const submitHandle = async () => {
    if (
      !brand ||
      // !esn ||
      // !longDescription ||
      // !shortDescription ||
      // !sim ||
      // !phoneNumber ||
      !brandType
      // (!screenshot && !screenshot2) ||
      // (uploadMedia[0] == null && uploadMedia[1] == null)
    ) {
      return toast.error("Type or brand fields required!");
    }
    setLoading(true);
    const formData = new FormData();
    const plainText = longDescription.replace(/<\/?[^>]+(>|$)/g, "");
    const data = {
      brand: brand,
      esn: esn,
      "longDescription": plainText,
      shortDescription,
      sim,
      number: phoneNumber,
      type: brandType,
    };


    try {
      if (editData) {
        await updateIncident(editData?.id, data)

      } else {
        formData.append("data", JSON.stringify(data));
        uploadMedia.forEach((item) => {
          if (item) {
            formData.append("uploadMedia", item);
          }
        });
        if (screenshot) {
          formData.append("pasteScreenshots", screenshot);
        }
        if (screenshot2) {
          formData.append("pasteScreenshots", screenshot2);
        }
        const res = await createIncident(formData);


      }
      setLoading(false);
      closeHandle()
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
      console.log("error", error);
    }
  };

  const handlePaste = (event: any, value: string) => {
    // Prevent default paste behavior
    event.preventDefault();
  
    // Get the clipboard items
    const items = event.clipboardData.items;
  
    // Loop through the clipboard items
    for (let i = 0; i < items.length; i++) {
      // Check if the item is an image or video and if the value matches 'index0' or 'index1'
      if ((items[i].type.indexOf('image') !== -1 || items[i].type.indexOf('video') !== -1) 
          && (value === "index0" || value === "index1")) {
  
        // Get the file (image or video)
        const file = items[i].getAsFile();
  
        if (value === "index0") {
          setUploadMedia((prev: any[]) => {
            let data = [...prev];
            data[0] = file;
            return data;
          });
        }
  
        if (value === "index1") {
          setUploadMedia((prev: any[]) => {
            let data = [...prev];
            data[1] = file;
            return data;
          });
        }
  
        break;
      } 
      // Handle only images for 'screenshot' and 'screenshot2'
      else if (items[i].type.indexOf('image') !== -1) {
        const file = items[i].getAsFile();
  
        if (value === "screenshot") {
          setScreenshot(file);
        } else if (value === "screenshot2") {
          setScreenshot2(file);
        }
  
        break;
      }
    }
  };

  const handleGrammar = async ()=>{
    if(!longDescription) return
    try {
      setGrammarLoading(true)
      let res:any = await grammarPrompt(longDescription)
      if(res?.status){
        setGrammarLoading(false)
        setLongDescription(res?.message)
      }
      
    } catch (error) {
      setGrammarLoading(false)
      console.log("error" , error)
    }
  }
  

  const handleButtonClick = () => {

    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.onpaste = (event: any) => handlePaste(event, "screenshot");
    }
  };

  const mediaPasteEnter = (index:string) => {

    if(index == "index0"){
      if (mediaRef.current) {
        mediaRef.current.focus();
        mediaRef.current.onpaste = (event: any) => handlePaste(event, index);
      }
    } else if (index == "index1"){
      if (mediaRef2.current){
        mediaRef2.current.focus();
        mediaRef2.current.onpaste = (event: any) => handlePaste(event, index);
      }
    }
  };


  const handleButtonClick2 = () => {
    if (inputRef2.current) {
      inputRef2.current.focus();
      inputRef2.current.onpaste = (event: any) => handlePaste(event, "screenshot2");
    }
  };

  return (
    <>
      <div className="loaction-list-main" >
        <div className="issue-main">
          <div className="head">
            <h3>Incident Form</h3>
          </div>
          <div className="issue-details-main new-incident-main">
            <div className="input-group">
              <div className="input-field">
                <label>Select Type</label>
                <div className="incident-dropdown">
                  {typeDropdownInput ? (
                    <button
                      id="btn"
                      onClick={() => {
                        setType(!type);
                        setBrandToggle(false);
                      }}
                    >
                      {brandType || "Select"}{" "}
                      <img src="images/angle_down_grey.svg" alt="" />
                    </button>
                  ) : (
                    <>
                      <input
                        id="btn"
                        type="text"
                        onClick={handleTypeClick}
                        onChange={(e: any) => setBrandType(e.target.value)}
                        value={brandType}
                        placeholder="Type"
                      />{" "}
                    </>
                  )}

                  {type && (
                    <div className="dropdown-incident">
                      <div className="search" id="search">
                        <input
                          id="search"
                          type="search"
                          placeholder="Search by name"
                          value={searchQuery}
                          onChange={handleTypeSearch}
                        />
                        <span>
                          <img
                            id="search"
                            src="images/search_icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <ul>
                        {typeItems?.map((item: any, index: any) => (
                          <li
                            id={index}
                            key={index}
                            style={item === "Other" ? { color: "#EE0000" } : {}}
                            onClick={() => handleClick(item)}
                          >
                            {item}
                          </li>
                        ))}
                        <li
                          style={{ color: "#EE0000" }}
                          onClick={() => handleClick("Other")}
                        >
                          Other
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="input-field">
                <label>Brand</label>
                <div className="incident-dropdown">
                  {typeBrandInput ? (
                    <button
                      id="btn"
                      onClick={() => {
                        setBrandToggle(!brandToggle);
                        setType(false);
                      }}
                    >
                      {brand || "Select"}{" "}
                      <img src="images/angle_down_grey.svg" alt="" />
                    </button>
                  ) : (
                    <>
                      <input
                        id="btn"
                        type="text"
                        onClick={handleTypeBrandClick}
                        onChange={(e: any) => setBrand(e.target.value)}
                        value={brand}
                        placeholder="Type"
                      />{" "}
                    </>
                  )}

                  {brandToggle && (
                    <div className="dropdown-incident">
                      <ul>
                        {dynamicTypeOrBrand?.brands?.map(
                          (item: any, index: any) => (
                            <li
                              id={index}
                              key={index}
                              style={
                                item === "Other" ? { color: "#EE0000" } : {}
                              }
                              onClick={() => handleClickItems(item)}
                            >
                              {item}
                            </li>
                          )
                        )}
                        <li
                          style={{ color: "#EE0000" }}
                          onClick={() => handleClickItems("Other")}
                        >
                          Other
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="input-field">
                <label>Phone</label>
                <input
                  type="text"
                  placeholder="Phone number"
                  name={"phoneNumber"}
                  onChange={handleChange}
                  value={data.phoneNumber}
                />
              </div>
              <div className="input-field">
                <label>ESN</label>
                <input
                  type="text"
                  placeholder="ESN"
                  name="esn"
                  value={data?.esn}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="input-group">
              <div className="input-field">
                <label>SIM</label>
                <input
                  type="text"
                  placeholder="SIM"
                  name="sim"
                  value={data.sim}
                  onChange={handleChange}
                />
              </div>
              <div className="input-field input-short">
                <label>
                  Short Description{" "}
                  <b
                    style={{
                      color: "#4F4F4F",
                      fontWeight: "normal",
                      fontSize: "12px",
                    }}
                  >
                    (Max 75 characters)
                  </b>
                </label>
                <input
                  type="text"
                  placeholder="Type Something...."
                  name="shortDescription"
                  value={data.shortDescription}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="input-group">
              <div className="input-field input-text">
                <label htmlFor="">Long Description / Details</label>
                {/* <CustomTextArea  actualValue={longDescription}
                  setLongDescription={setLongDescription}/> */}
                  <div className="longDescription" style={{position:"relative"}}>
                  <textarea
                  style={{resize:"none"}}
                  placeholder="Type something..."
                  name="longDescription"
                  value={longDescription}
                  onChange={(e:any)=>setLongDescription(e.target.value)}
               />
                 
            
                <span onClick={handleGrammar} style={{position:"absolute" , bottom:"58px" , cursor:"pointer"}}>
                {grammarLoading ? <span><Loading/>  </span>:  <img src={grammarIcon} alt="grammar icon" />}

                </span>
                  </div>
              
                {/* <button onClick={handleGrammar} disabled={grammarLoading}>{grammarLoading ? <Loading/> : " correct grammar"}</button> */}
              </div>
            </div>
          
            <div className="new-upload-media">
            
              <div className="drag-main">
              {
                editData && uploadMedia ? <div className="drag-head">
                <label htmlFor="">Uploaded Media <span>( Photo or Video : max 30 seconds )</span></label>
              </div> : !editData ? <div className="drag-head">
                <label htmlFor="">Upload Media <span>( Photo or Video : max 30 seconds )</span></label>
              </div> : ""
              }
                {uploadMedia?.map((item: any, index) => {
                  const isVideo = editData ? (item?.endsWith('.mp4') || item?.endsWith('.mov') || item?.endsWith('.wmv') || item?.endsWith('.avi')) : false
                  return (
                    <div className="drag" 
                    >
                      {item ? (
                        <>
                          {typeof item == "string" ? (
                            <>
                              {isVideo ? (
                                <video controls>
                                  <source src={item} />
                                </video>
                              ) : (
                                <img src={item} height={100} width={200} alt="upload" />
                              )}

                            </>
                          ) : item?.type?.startsWith("video/") ? (
                            <video height={100} width={200} controls={true}>
                              <source
                                src={URL.createObjectURL(item)}
                                type={item.type}
                              />
                            </video>
                          ) : (
                            <img
                              src={URL.createObjectURL(item)}
                              height={100}
                              width={200}
                              alt="upload"
                            />
                          )}
                         {!editData && <button className="delete-btn" onClick={() => handleMediaTrash(index)}><img src="images/delete_new_icon.svg" alt="" /></button> } 
                        </>
                      ) : (
                        <>
                          <div className="details" 
                          
                          
                           onDrop={(event: any) => handleDrop(event, `index${index}`)}
                            onDragOver={handleDragOver}
                            // onPaste={(event: any) => handlePaste(event, `index${index}`)}
                            onMouseEnter={()=>{
                              mediaPasteEnter(`index${index}`)
                            }}
                            >
                              <input
                             ref={
                              index == 0 ? mediaRef : mediaRef2
                            }
                              onPaste={(event: any) => {
                                if(index == 0){
                                  handlePaste(event, `index${index}`)
                                }else{
                                  handlePaste(event, `index${index}`)
                                }
                              }}
                              style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}
                            />
                            <h4>Drag an image here</h4>
                            <span>or click the button</span>
                            <div className="drag-or-paste">
                              <button><img src="images/upload_icon.svg" alt="" />
                                <input
                                  type="file"
                                  disabled={editData}
                                  accept="image/*,video/*"
                                  onChange={(event) => handleMedia(event, index)}
                                /></button>
                              <span>or</span>
                              <button><img src="images/paste_icon.svg" alt="" /></button>
                            </div>
                          </div>
                        </>
                      )
                      }


                    </div>


                  )
                })
                }


              </div>
              <div className="drag-main">
                {
                  editData && (!screenshot && !screenshot2) ? "" :  <div className="drag-head">
                  <label htmlFor="">Calculator Screenshot</label>
                </div>
                }
               
                {
                  !screenshot && editData ? "" : <div className="drag" onDrop={(event: any) => handleDrop(event, "screenshot")}
                    onDragOver={handleDragOver} >

                    {typeof screenshot === "string" ? (
                      <>
                        <img
                          src={screenshot}
                          height={100}
                          width={200}
                          alt="upload"
                        />
                      </>
                    ) : screenshot ? (
                      <>
                        {" "}
                        <img
                          src={URL.createObjectURL(screenshot)}
                          height={100}
                          width={200}
                          alt="upload"
                        />

                        <button className="delete-btn" onClick={() => setScreenshot(null)}><img src="images/delete_new_icon.svg" alt="" /></button>

                      </>
                    )
                      : <div className="details"
                        onMouseEnter={handleButtonClick}
                      >
                        <h4>Drag an image here</h4>
                        <span>or click the button</span>
                        <div className="drag-or-paste">
                          <button><img src="images/upload_icon.svg" alt="" />  <input
                            type="file"
                            disabled={editData}
                            onChange={(event) => handleImage(event, 0)}
                          /></button>
                          <span>or</span>
                          <button onClick={handleButtonClick}>
                            <input
                              ref={inputRef}
                              onPaste={(event: any) => handlePaste(event, "screenshot")}
                              style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}
                            />
                            <img src="images/paste_icon.svg" alt="" />
                          </button>
                        </div>
                      </div>
                    }

                  </div>
                }


                {
                  !screenshot2 && editData ? "" : <div className="drag"  >

                    {typeof screenshot2 === "string" ? (
                      <>
                        <img
                          src={screenshot2}
                          height={100}
                          width={200}
                          alt="upload"
                        />
                      </>
                    ) : screenshot2 ? (
                      <>
                        {" "}
                        <img
                          src={URL.createObjectURL(screenshot2)}
                          height={100}
                          width={200}
                          alt="upload"
                        />

                      {!editData && <button className="delete-btn" onClick={() => setScreenshot2(null)}><img src="images/delete_new_icon.svg" alt="" /></button> }  

                      </>
                    )
                      : <div className="details" 
                        onDrop={(event: any) => handleDrop(event, "screenshot2")}
                        onDragOver={handleDragOver}
                        onMouseEnter={handleButtonClick2}
                      >
                        <h4>Drag an image here</h4>
                        <span>or click the button</span>
                        <div className="drag-or-paste">
                          <button><img src="images/upload_icon.svg" alt="" />  <input
                            type="file"
                            disabled={editData}
                            onChange={(event) => handleImage(event, 1)}
                          /></button>
                          <span>or</span>
                          <button onClick={handleButtonClick2}>
                            <input
                              ref={inputRef2}
                              onPaste={(event: any) => handlePaste(event, "screenshot2")}
                              style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}
                            />
                            <img src="images/paste_icon.svg" alt="" />
                          </button>
                        </div>
                      </div>
                    }

                  </div>
                }
              </div>
            </div>

            <div className="submit-btn">
              <button onClick={submitHandle} disabled={loading}>
                {" "}
                {loading ? <Loading /> : "SUBMIT"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewIncidents;
