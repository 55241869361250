import config from "../atlas-app-services/config.json"
import * as Realm from "realm-web";

/**
 * Provides functions for managing changes to the tasks in the Realm,
 * such as adding, updating, and deleting tasks.
 */
export function useApplication() {
  
    const app = new Realm.App({ id: process.env.REACT_APP_ATLAS_APP_ID || "" });
  return {
    app
  };
}