import { useEffect, useState } from "react"
import ConfirmEmail from "./ConfirmEmail"
import Login from "./Login"
import VerifyOtp from "./VerifyOtp"
import ResetPassword from "./ResetPassword"
import { useNavigate } from "react-router-dom"
import { getRole, getToken } from "../../utils/common"
import SignUp from "./SignUp"
import { verifyOtp } from "../../config/api"
import toast from "react-hot-toast"

function Main() {
  const [forgotScreen, setForgotScreen] = useState<string>("login")

  const [email, setEmail] = useState("")
  const [loading, setIsLoading] = useState(false)


  const navigate = useNavigate()
  const token = getToken()
  const role = getRole()
  useEffect(() => {
    
    if (token) {
      if (role === "admin") {
        navigate("/website-users")
      } else if(role === "sales_member") {
        navigate("/calculator")
      }else{
        navigate("/")
      }
    }
  }, [token , role])

  const handleForgot = (value: string) => {
    setForgotScreen(value)
  }

  const backHandler = (value: string) => {
    setForgotScreen(value)
  }

  const verifySubmit = async (otp:string)=>{
    try {
      setIsLoading(true)
      let res:any = await verifyOtp({otp , email})
      if(res?.status){
        handleForgot("ResetPassword")
      }
      setIsLoading(false)
    } catch (error:any) {
      toast.error(error?.message)
      setIsLoading(false)
      console.log(error , "error___________-")
    }
  }

  return (
    <section id="Dashboard-login-main" className="website-login-main web-signUp-main">
      <div className="login-img-right">
        <img src="images/login_img.png" alt="" />
      </div>
      <div className="dashboard-input-left">
        {forgotScreen === "signUp" && <SignUp handleForgot={handleForgot} />}
        {forgotScreen === "login" && <Login handleForgot={handleForgot} />}
        {forgotScreen === "ConfirmEmail" && <ConfirmEmail email={email} setEmail={setEmail} backHandler={backHandler} handleForgot={handleForgot} />}
        {forgotScreen === "VerifyOtp" && <VerifyOtp resendOtpLoading={false} isLoading={loading} email={email} backHandler={backHandler} handleForgot={handleForgot} verifySubmit={verifySubmit}  />}
        {forgotScreen === "ResetPassword" && <ResetPassword email={email} backHandler={backHandler} />}
      </div>

    </section>
  )
}

export default Main
