

import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import 'rsuite/dist/rsuite.min.css';
import Main from "./components/auth/Main";
import Dashboard from "./pages/dashboard/Dashboard";
import Setting from "./pages/setting/Setting";



import "./assets/css/style.css";
import "./assets/css/responsive.css";

import WebDashboard from "./website/pages/WebDashboard";
import WebProfileScreen from "./website/pages/WebProfileScreen";


import { AppProvider } from '@realm/react';

import { AuthenticatedApp } from './AuthenticatedApp';
import { AdminAuthenticatedApp } from "./AdminAuthenticatedApp";
import config from './atlas-app-services/config.json';
import WebsiteUsers from "./admin/WebsiteUsers";
import IncidentsList from "./admin/IncidentsList";
import IncidentMain from "./website/pages/IncidentMain";
import WebSettings from "./website/pages/WebSettings";
import Calculator from "./website/pages/Calculator";
import AdminIncidentView from "./admin/AdminIncidentView";
import init from "../src/pkg/demo_wasm"
import { useEffect } from "react";


function App() {
  useEffect(() => {
    const integrateWasm = async()=>{
      try {
       await init()

      } catch (error) {
        console.log("error" , error)
      }
    }
 
      integrateWasm()
    
   
    
  }, [])



  return (
    <>
      <AppProvider id={process.env.REACT_APP_ATLAS_APP_ID || ""} >
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/dashboard" element={<AdminAuthenticatedApp><Dashboard /> </AdminAuthenticatedApp>} />
        <Route path="/setting" element={<AdminAuthenticatedApp><Setting /> </AdminAuthenticatedApp>} />
        <Route path="/calculator" element={<AuthenticatedApp><Calculator /> </AuthenticatedApp>} />
        <Route path="/website-users" element={<AdminAuthenticatedApp><WebsiteUsers /> </AdminAuthenticatedApp>} />
        <Route path="/incidents" element={<AdminAuthenticatedApp><IncidentsList /> </AdminAuthenticatedApp>} />
        <Route path="/incident-view/:id" element={<AdminAuthenticatedApp><AdminIncidentView /> </AdminAuthenticatedApp>} />
        <Route path="/website-incidents" element={<AuthenticatedApp><IncidentMain  /> </AuthenticatedApp>} />
        <Route path="/setting-profile" element={<AuthenticatedApp><WebSettings /> </AuthenticatedApp>} />
        <Route path="*" element={<h1>No Page Found</h1>} />
      </Routes>
      <Toaster/>
      </AppProvider>
    </>
  );
}

export default App;
