import React from 'react';
import { Loader } from 'rsuite';

const InitialLoading = () => {
  return (
    <div style={{display:"flex" , justifyContent:"center" , alignItems:"center" , height:"70vh"}}>
       <Loader  size={"md"}  />
    </div>
  );
}

export default InitialLoading;
