import { NavLink, useLocation } from "react-router-dom";
import AppContext from "../../context/AppProvider";
import { useContext, useEffect } from "react";
import { getProfileData } from "../../config/api";
import dashboard_logo from "../../assets/images/Logo.png";
import User_logo from "../../assets/images/user_icon.svg";
import issue_icon from "../../assets/images/issue_icon.svg";
import setting_icon from "../../assets/images/setting_icon.svg";

const SideMenu = ({activeMenu , setActiveMenu }:{activeMenu:any , setActiveMenu:any , }) => {
    const {mobileToggle , setMobileToggle , setProfileData} = useContext(AppContext)
    useEffect(()=>{
      const getData =async  ()=>{
        try {
          const res:any = await getProfileData()
          setProfileData(res?.data)
        } catch (error) {
          console.log("error" , error)
        }
      }
      getData()

    },[])

    const location = useLocation()


  return (
    <div className={`dashboard-menu  ${activeMenu ? "dashboard-active" : ""} ${mobileToggle ? "menu-active" : ""}`}>
          <div className="logo">
              <div className="logo-img">
                  <a ><img src={dashboard_logo} alt="Logo image" />
                  </a>
              </div>
              <div className="tab-menu-bar" onClick={()=>setActiveMenu(!activeMenu)}>
                  <a  className="barBtn" style={{cursor:"pointer"}}><img src="images/bar_icon.svg" alt="" />
                  </a>
                  <a className="closeBtn-mobile" onClick={()=>setMobileToggle(false)} ><img src="images/times_icon.svg" alt="" /></a>
              </div>
          </div>
          <div className="dashboard-options">
              <ul>
                  
                  <li >
                    <NavLink className={({ isActive }) => isActive  ? "active" : ""}  to="/website-users"><span className="menu-icon">
                    <img src={User_logo} alt=""/>
                                  </span>
                          <span >Website Users</span></NavLink>
                    </li>
                  <li >
                    <NavLink className={({ isActive }) => (isActive ||  location.pathname.includes("/incident-view") ) ? "active" : ""}  to="/incidents"><span className="menu-icon"><img src={issue_icon}
                                  alt="" /></span>
                          <span >Incidents</span></NavLink>
                    </li>
                  <li>
                    <NavLink className={({ isActive }) => isActive  ? "active" : ""} to="/setting"><span className="menu-icon"><img src={setting_icon} alt="" /></span>
                  <span >Settings</span></NavLink>
                  </li>
              </ul>
          </div>
      </div>
      
  );
}

export default SideMenu;
