

import React, { useContext, useEffect, useState } from "react";
import TableRow from "../../components/TableRow";
import { useTaskManager } from "../../hooks/useTaskManager";
import  { add_data, apply_common_filters } from "../../pkg/demo_wasm"
import AppContext from "../../context/AppProvider";


const WebDashboard = () => {

  const arr = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

  const plan = ["$40", "$55", "$65"]
  const [selectedType, setSelectedType] = useState("NEW")
  const [selectedPlan, setSelectedPlan] = useState("$40")
  const [selectedLine, setSelectedLine] = useState(0)
  const [calculatedPlan, setPlan] = useState<number>(0)
  const [todayPLan, setTodayPLan] = useState<number>(0)
  const [autoPay, setAutoPay] = useState("")
  const [resultId, setResultId] = useState<any>()
  const [dropDownAction, setDropDownAction] = useState(false)
  const [dropDown, setDropDown] = useState()

  const [newPrice, setNewPrice] = useState<any>([])
  const [selectedSheet, setSelectedSheet] = useState<any>("")

  const { sheets } = useTaskManager();
  const { profileData } = useContext(AppContext)

  useEffect(() => {
    if(profileData){
      setSelectedSheet(profileData?.permissions?.calculator?.sheets[0].sheetId)
    }
  }, [profileData])


  useEffect(() => {
    
    if (sheets.length > 0) {
      const processData = (data: any) => {
        let result = '';

        data.forEach((entry: any) => {
          result += entry.fullText + '|||';
        });

        return result.slice(0, -3);
      };

      const result = processData(sheets);
      
      try {
        const payValue = autoPay || "(blank)";
        add_data(result)
       apply_common_filters(selectedSheet,selectedType, String(selectedLine), selectedPlan, payValue)
      } catch (error) {
        console.log(" add_data error wasm" , error)
      }
    }

  }, [sheets]);


  useEffect(() => {
   

    const payValue = autoPay || "(blank)";
    try {
      const resultedData = apply_common_filters(selectedSheet,selectedType, String(selectedLine), selectedPlan, payValue)
    
      setResultId(resultedData)
    } catch (error) {
      console.log("apply_common_filters wasm" , error)
    }
  
  

  }, [selectedSheet , selectedType, selectedLine, selectedPlan, autoPay])



  const handleDecrease = () => {

    setSelectedLine((prev: number): any => {
      if (prev > 0) {
        return prev - 1
      } else {
        return 0
      }
    })
  }


  const handleIncrease = () => {

    setSelectedLine((prev: number): any => {
      if (prev < 10) {
        return prev + 1
      } else {
        return 10
      }
    })

  }




  const handleAutoPay = (value: string) => {

    setAutoPay(value)
  }


  const getResultValue = (itemResult: any) => {
    setNewPrice((prevValue: any) => {
      let item = [...prevValue]
      const findObject = item.find((i: any) => itemResult.id == i.id)
      if (findObject) {
        const seprateValue = item.filter((argValue) => {
          return argValue.id != itemResult.id
        })

        return [...seprateValue, itemResult]

      } else {
        return [...prevValue, itemResult]
      }

    })
  }

  const handleSheetValue = (argValue:string)=>{
    setSelectedSheet(argValue)
  }



  useEffect(() => {

    const totalTodayPlan = newPrice.reduce((accumulator: any, item: any) => {
      return accumulator + (item.planValue ? item.planValue : 0)
    }, 0);

    setTodayPLan(totalTodayPlan);

    const totalPlan = newPrice.reduce((accumulator: any, item: any) => {
      return accumulator + (item.monthlyValue ? item.monthlyValue : 0)
    }, 0);
    setPlan(totalPlan);

  }, [newPrice])

  return (
    <>

      <div className="loaction-list-main" style={{ border: "none" }}>
        <div className="calculator-list-main">

          <div className="select-main">

            <div className="select-box">
              <h2>PHONE <span>LINES</span></h2>
              <ul className="arrow-btns">
                <li className="previous-btn" onClick={handleDecrease}><img src="images/angle_left_new.svg" alt="" /></li>
                <li className="number-count">{selectedLine}</li>
                <li className="next-btn" onClick={handleIncrease}><img src="images/angle_left_new.svg" alt="" /></li>
              </ul>

            </div>
            <div className="select-box">
              <h2>SELECT <span>PLAN</span></h2>
              <ul>
                {
                  plan.map((item: string, index) => {
                    return (
                      <li key={index} onClick={() => {

                        setSelectedPlan(item)
                      }} className={selectedPlan == item ? "active" : ""} >{item}</li>
                    )
                  })
                }

              </ul>
            </div>

            <div className="select-box">
              <h2>SELECT <span>AutoPay</span></h2>
              <ul>
                <li onClick={() => handleAutoPay("")} className={autoPay === "" ? "auto-box blank active" : 'auto-box'}>{""}</li>
                <li onClick={() => handleAutoPay("Yes")} className={autoPay === "Yes" ? "active" : ''}>Yes</li>
                <li onClick={() => handleAutoPay("No")} className={autoPay === "No" ? "active" : ''}>No</li>
              </ul>
            </div>
            <div className="total-payout-main">
              <div className="total">
                <div className="head">
                  <h3>PLAN</h3>
                </div>

                <b>${calculatedPlan.toFixed(2)}<span>/mo</span></b>
              </div>
              <div className="total">
                <div className="head">
                  <h3>TODAY</h3>
                </div>
                <b>${todayPLan.toFixed(2)}</b>
              </div>
            </div>
            <div className="select-box">
              <h2>SELECT <span>Sheet</span></h2>
              <ul>
                {
                   profileData &&  profileData?.permissions?.calculator?.sheets?.map((value:any) => {
                    return (
                      <>
                        <li onClick={() => handleSheetValue(value.sheetId)} className={selectedSheet === value.sheetId ? "active" : ''}>{value.sheetNumber}</li>
                      </>
                    )
                  })
                }

              </ul>
            </div>
          </div>
          <div className="table-data-main table-data-dropdown">
            <div className="table-data">
              <table border={0} cellPadding={0} style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="heading web-table-heading">Devices</th>
                    <th className="heading web-table-heading">Port In</th>
                    <th className="heading web-table-heading">ID Veriff</th>
                    <th className="heading web-table-heading">Device Price</th>
                    <th className="heading web-table-heading">Plan Today</th>
                    <th className="heading web-table-heading">Plan Monthly</th>
                    <th className="heading web-table-heading">Set Up Fee</th>
                    <th className="heading web-table-heading" style={{ width: "30%" }}>Notes</th>
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                  {
                    arr?.map((value, index) => {
                      return (
                        <React.Fragment key={Number(value)}>
                          <TableRow
                            id={value}
                            resultId={resultId}
                            getResultValue={getResultValue}
                            dropDownAction={dropDownAction}
                            setDropDownAction={setDropDownAction}
                            dropDown={dropDown}
                            setDropDown={setDropDown}
                          />

                        </React.Fragment>

                      )
                    })
                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default WebDashboard;
