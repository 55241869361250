import React, { useContext, useEffect, useState } from 'react';
import { getWebsiteIncident } from '../../config/api';
import { getuid } from 'process';
import { formatDate, formatTime, getUserId, maskNumber } from '../../utils/common';
import Pagination from '../../components/common/Pagination';
import PageLoading from '../../components/common/PageLoading';
// import moment from 'moment';
import Illustration from '../../components/common/Illustration';
import moment from "moment-timezone"
import { LuEye } from 'react-icons/lu';
import AppContext from '../../context/AppProvider';

const MyIncidents = ({setViewData , setNewIncident , type}:{setViewData:any , setNewIncident:any , type:string}) => {


  const [data , setData] = useState<any>(null)
  const [params , setParams] = useState({
    page:1,
    perPage:10,

})

const {profileData} = useContext(AppContext)
const [loading , setLoading] = useState(false)


const formatDate = (dateString: any) => {
  const format = 'DD-MM-YYYYTHH:mm:ss.SSSSZ'; // Adjust if the format is slightly different

  // Parse the date string with the specified format
  const momentDate = moment(dateString, format, true); 
  if (!momentDate.isValid()) {
    return 'Invalid Date'; // Fallback for invalid dates
  }

  return momentDate.tz('America/New_York').format('MM/DD/YYYY');
};

const formatTime = (dateString: string) => {
  const format = 'DD-MM-YYYYTHH:mm:ss.SSSSZ'; // Adjust if the format is slightly different

  const momentDate = moment(dateString, format, true);

  if (!momentDate.isValid()) {
    return 'Invalid Time'; // Fallback for invalid dates
  }

  return momentDate.tz('America/New_York').format('h:mm A'); // Format for time only
};

  useEffect(()=>{
    const getData = async ()=>{
      try {
        setLoading(true)
        const userId = getUserId()
        let res:any = await getWebsiteIncident(params.page , params.perPage , type )
        if(res?.status){
          setData(res?.data)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error , "errorr")
      }
    }
    getData()

  },[type , params])

   //* pagination page handler 
   const pageHandler = (e: any) => {
    const page = e.selected + 1
    setParams({ ...params, page })
  }
   const handleView = (item:any) => {
    setViewData(item)
  }

  const isUserIncident = (userId:string)=>{

    const isIncident = userId === profileData?.id
    return isIncident

  }
  return (
    <>
    <div className='loaction-list-main' style={{verticalAlign:"top", borderTopRightRadius:"unset", borderTopLeftRadius:"unset"}}>

      {
        loading ? <PageLoading/> : data?.total == 0 ? <Illustration imgLink={"/images/noIncident.svg"} title="No Incidents Yet!"/> :  (
          <>
           <div className="table-data-main incident-main-table" style={{padding:"0"}}>
            <div className="table-data" style={{height:"65vh", overflowY:"auto"}}>
            <table border={0} cellPadding={0} style={{width: "100%"}}>
                <thead
                  style={{
                    backgroundColor: "#fdfdfd",
                    borderBottom: "1px solid #ededed",
                    borderTop: "1px solid #ededed"
                  }}
                >
                  <tr>
                    <th className="heading">Incident ID</th>
                    <th className="heading">Date</th>
                    <th className="heading">Type</th>
                    <th className="heading">Short Desc.</th>
                    <th className="heading">Brand</th>
                    <th className="heading">Phone</th>
                    <th className="heading">ESN</th>
                    <th className="heading">SIM</th>
                    <th className="heading">Store</th>
                    {/* <th className="heading">Rep Name</th> */}
                    <th className="heading" style={{width: "7%"}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.incidents?.map((item:any)=>{
                      return (
                        <>
                          <tr style={{ borderBottom: "1px solid #ededed" }}>


                            <td className="table-col">{item.inc}</td>
                            <td className="table-col">{formatDate(item?.date)}<br></br>
                            <p style={{textAlign:"left"}}>{formatTime(item?.date)}</p>
                            </td>
                            <td className="table-col">{item.type}</td>
                            <td className="table-col">{item.shortDesc}</td>
                            <td className="table-col">{item.brand}</td>
                            <td className="table-col">{isUserIncident(item?.userId) ?  item.phone : maskNumber(item.phone)}</td>
                            <td className="table-col">{isUserIncident(item?.userId) ? item?.esn :  maskNumber(item.esn)  }</td>
                            <td className="table-col">{isUserIncident(item?.userId) ?  item.sim :  maskNumber(item.sim)}</td>
                            <td className="table-col">{isUserIncident(item?.userId) ? item.store : maskNumber(item.store)}</td>
                            {/* <td className="table-col">{item.repName}</td> */}

                            <td style={{ textAlign: "center" }} className="table-col">

                              <button className="action-btn" onClick={()=>handleView(item)}>
                              <LuEye size={20} />
                              </button>
                            </td>
                          </tr>
                        </>
                      )
                    })
                  }
                
                </tbody>
              </table>
            </div>
      </div>
          </>
        )
      }

   
    </div>

    {/* <div className="next-previous-slide">
        <div className="slides-heading">
          <span>Showing 1 to 8 of 90</span>
        </div>
        <div className="slides-button">
          <ul>
            <li>
              <a href="#"><img src="images/angle_left.svg" alt="" /> </a>
            </li>
            <li><a className="active-slide" href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">4</a></li>
            <li>
              <a href="#"><img src="images/angle_right.svg" alt="" /> </a>
            </li>
          </ul>
        </div>
      </div> */}
      {
        data?.total > 0 &&  <Pagination paramsData={params} total={data?.total} pageCount={data?.totalPages} handlePageChange={pageHandler} handlePageSelect={null} increasePageSize={false} />
      }
   
    </>
  );
}

export default MyIncidents;
