
const LeftArrow = () => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.121 5.00005L5.8335 1.28755L4.773 0.227051L-3.8147e-06 5.00005L4.773 9.77305L5.8335 8.71255L2.121 5.00005Z" fill="#243D8A"/>
    </svg>
    
  );
}

export default LeftArrow;
