import ReactPaginate from 'react-paginate';
import LeftArrow from '../../assets/svg/LeftArrow';
import RightArrow from '../../assets/svg/RightArrow';
import "./pagination.css";
import { getPaginationText } from '../../utils/common';
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useState } from 'react';


const Pagination = ({paramsData , total ,  pageCount , handlePageChange , handlePageSelect , increasePageSize}:{paramsData:any , total:number ,  pageCount:number  , handlePageChange:any , handlePageSelect:any , increasePageSize:any}) => {
  const [toggle , setToggle] = useState(false)


  const handleSelect = (v:number)=>{
    handlePageSelect(v)
    setToggle(false)
  }
  return (
    <>
      <div className="next-previous-slide">
          <div className="slides-heading">
            <div className='page-number'><span> {getPaginationText(paramsData?.page, paramsData?.perPage, total || 0)}</span>
           {
              increasePageSize &&  (
                <>
                  <MdOutlineKeyboardArrowUp size={30} onClick={()=>setToggle(!toggle)} style={{cursor:"pointer"}} />
            {
              toggle &&  <div className='up-dropdown'>
              <ul>
                <li onClick={()=>handleSelect(10)}>10</li>
                <li onClick={()=>handleSelect(20)}>20</li>
                <li onClick={()=>handleSelect(50)}>50</li>
                <li onClick={()=>handleSelect(100)}>100</li>
              </ul>
            </div>
            }
                </>
              )
            } 
              
            </div>
          
          </div>
          <div className="slides-button">
           
            <ReactPaginate
          breakLabel="..."
          nextLabel={<RightArrow/>}
          onPageChange={handlePageChange}
          // pageRangeDisplayed={5}
          pageCount={pageCount ?? 0}
          previousLabel={<LeftArrow/>}
          renderOnZeroPageCount={null}
          className="react_pagination"
          pageClassName="listStyle"
          activeClassName="active_page"
          activeLinkClassName="active_link"
          nextClassName="next_button_style"
          previousClassName="previous_button_style"
        />
          </div>
        </div>
    </>
  )
}

export default Pagination;
