import { useEffect, useState } from 'react';
import { addPermission } from '../../config/api';
import toast from 'react-hot-toast';


const SheetSelect = ({ sheetNumber, value ,setData}: any) => {
    const [selectedSheets, setSelectedSheets] = useState<{sheetNumber: string, sheetId: string}[]>([]);

    // Initialize selectedSheets state with value from props
    useEffect(() => {
      if (value?.permissions?.calculator?.sheets) {
        const initialSheets = value.permissions.calculator.sheets.map((sheet: any) => ({
          sheetNumber: sheet.sheetNumber, 
          sheetId: sheet.sheetId
        }));
        setSelectedSheets(initialSheets);
      }
    }, [value]);
  
    const handleCheckboxChange = (item: any, isChecked: boolean) => {
      let updatedSelectedSheets;
  
      if (isChecked) {
        // Add the selected sheetId and sheetNumber to the array
        updatedSelectedSheets = [...selectedSheets, { sheetNumber: item.name, sheetId: item.sheetId }];
      } else {
        // Remove the deselected sheet from the array
        updatedSelectedSheets = selectedSheets.filter((sheet) => sheet.sheetId !== item.sheetId);
      }
  
      // Update the state with the new selected sheets array
      // setSelectedSheets(updatedSelectedSheets);
  
      // Send the updated selected sheets to the API
      sendSelectedSheets(updatedSelectedSheets);
    };

    const sendSelectedSheets = async (sheets: {sheetNumber: string, sheetId: string}[]) => {
    const formData = new FormData()
    formData.append("firstName" , value.firstName)
    formData.append("lastName" , value.lastName)
    formData.append("phoneNumber" , value.number)
    formData.append("email" , value.email)
    formData.append("position" , value.position)
    formData.append("tspId" , value.tspId)
    formData.append("storeName" , value?.storeName)
    formData.append("positionShortName" , value?.positionShortName)
    formData.append("storeZipCode" , value.zipCode)
    formData.append("incidentExport" , value?.permissions?.incidentExport)
    if(sheets?.length > 0){
        formData.append("calculator" , "Yes")
    }else{
        formData.append("calculator" , "No")
    }
    formData.append("sheets" , JSON.stringify(sheets))
    try {
        const res:any = await addPermission(formData ,  value?.id)
        setData((prev: any) => {
          const websiteUsers = prev?.websiteUsers;
          const users = websiteUsers?.map((item: any) => {
            if (item?.id === value?.id) {
              return {
                ...item,
                permissions: {
                  ...item.permissions,
                  calculator: {
                    ...item.permissions.calculator,
                    sheets: sheets, 
                  },
                },
              };
            }
           
            return item;
          });
          return {
            ...prev,
            websiteUsers: users,
          };
        });
    } catch (error:any) {
      toast.error(error?.message)
      console.error('Error updating selected sheets:', error);
    }
  };

  return (
    <td className="table-col">
      <ul className='sheet-select'>
      {
          sheetNumber?.map((item: any) => {
            // Check if the current sheetId exists in the selectedSheets state
            const isChecked = selectedSheets.some(sheet => sheet.sheetId === item.sheetId);

            return (
              <li key={item?.sheetId}>
                <input 
                  type="checkbox" 
                  checked={isChecked} 
                  onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                />
                <label>{item?.name}</label>
              </li>
            );
          })
        }
      </ul>
    </td>
  );
};

export default SheetSelect;
