import  { useContext, useEffect, useState } from 'react';
import Confirmation from '../popUps/Confirmation';
import { useNavigate } from 'react-router-dom';
import { useApplication } from '../../test/useApplication';
import AppContext from '../../context/AppProvider';
import { useAuth } from '@realm/react';

const ProfileSection = ({formData , handleProfile}:{formData:any ,  handleProfile:()=> void}) => {
    const [profileScreen , setProfileScreen] = useState(false)
    const [logoutPopup , setLogoutPopup] = useState(false)
   

    const {app} = useApplication()
    const { logOut } = useAuth();

   
    const navigate = useNavigate()

    const cancelHandle = ()=>{
        setLogoutPopup(false)
    }

  
    const submitHandle = ()=>{
        localStorage.clear()
        logOut()
        app.currentUser?.logOut()
        setLogoutPopup(false)
        navigate("/")
    }


  return (
    <>

{ logoutPopup &&  (<><Confirmation title={'Are you sure, you want to logout?'} isLoading={false} cancelHandle={cancelHandle} submitHandle={submitHandle} btnTitle={'Log Out'} /></>)}
     <div className="profile-picture">
                  <div className="profile-img">
                      <a style={{cursor:"pointer"}} onClick={()=>{setProfileScreen(!profileScreen)}}><img src={formData?.image ? formData?.image : "/images/profile_placeholder.svg"}  height={40} width={40}   style={{borderRadius:30}}/></a>
                      {
                        profileScreen &&  <div className='overlay' onClick={()=>{setProfileScreen(!profileScreen)}} >

                        <div className="profile-dropdown">
                            <div className="profile-info">
                                <div className="profile-image">
                                    <img src={formData?.image ? formData?.image : "/images/profile_placeholder.svg" } alt="" />
                                </div>
                                <h3>{formData?.firstName} {formData?.lastName}  </h3>
                                <span>{formData?.email}</span>
                            </div>
                            <div className="log-out">
                                <ul>
                                    <li><a onClick={handleProfile} style={{cursor:"pointer"}}  className="profile-btn"><img src="images/profile_user_icon.svg" alt="" />
                                            My Profile</a></li>
                                    <li onClick={()=>setLogoutPopup(true)} ><a style={{cursor:"pointer"}} className="logout-btn active" ><img src="images/logout_icon.svg" alt="" />
                                            Logout</a></li>
                                </ul>
                            </div>
                        </div>
                        </div> 
                      }
     
                  </div>
              </div>
      
    </>
  );
}

export default ProfileSection;
