import { useState } from "react";
import toast from "react-hot-toast";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { forgotPassword } from "../../config/api";
import Loading from "../common/Loading";


const ResetPassword = ({email , backHandler}:{email:string , backHandler:(a:string)=> void}) => {


  const [password , setPassword] = useState("")
  const [newPassword , setNewPassword] = useState("")
  const [passwordVisible , setPasswordVisible] = useState(false)
  const [confirmPasswordVisible , setConfirmPasswordVisible] = useState(false)
  const [isLoading , setIsLoading] = useState(false)



 
  const handleSubmit = async ()=>{
    if(!password || !newPassword) return
    if(password != newPassword) return toast.error("password and confirm password should be match!")
    try {
      setIsLoading(true)
      const res:any = await forgotPassword({
        confirmPassword: password,
        email,
        newPassword: newPassword
      })
      if(res?.status){
        setIsLoading(false)
        backHandler("login")
      }
     
    } catch (error:any) {
      toast.error(error?.message)
      setIsLoading(false)
      console.log("error" , error)
    }
   
    
  }
 
  return (
    <>
      <div className="login-back-btn" onClick={()=>backHandler("VerifyOtp")}>
              <a ><img src="images/left_arrow.svg" alt="" /></a>
            </div>
            <div className="logo">
              <a href="#"><img src="images/Logo.png" alt="Logo" /></a>
            </div>
            <div className="login-input-details">
              <h2>Password Reset</h2>
              <p>Please reset your password</p>
              <div className="input-group mb">
                <input type={ passwordVisible ?  "text" : "password"} value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="New password" />
                <span className="icon"
                  ><img className="mobile-icon" src="images/password_icon.svg" alt=""
                /></span>
                <span className="eye-icon"
               onClick={()=>setPasswordVisible(!passwordVisible)}    > {passwordVisible ? <LuEye size={20}/> : <LuEyeOff size={20}/>}</span>
              </div>
              <div className="input-group mb">
                <input type={ confirmPasswordVisible ?  "text" : "password"} value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} placeholder="Confirm New Password" />
                <span className="icon"
                  ><img className="mobile-icon" src="images/password_icon.svg" alt=""
                /></span>
                <span className="eye-icon"
                onClick={()=>setConfirmPasswordVisible(!confirmPasswordVisible)}   >{confirmPasswordVisible ? <LuEye size={20}/> : <LuEyeOff size={20}/>}</span>
              </div>
              <div className="login-btn forgot-btn">
                <button onClick={handleSubmit} disabled={isLoading}>{isLoading ? <Loading/> : "SUBMIT"}</button>
              </div>
            </div>
    </>
  );
}

export default ResetPassword;
