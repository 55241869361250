import { useEffect, useState } from "react";
import {
  registerSalesMember,
  updateProfile,
} from "../../config/api";
import axios from "axios";
import Loading from "../common/Loading";
import toast from "react-hot-toast";

const AddSaleMember = ({
  closeHandle,
  editMember,
  successCloseHandle,
}: {
  closeHandle: () => void;
  editMember: any;
  successCloseHandle: () => void;
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    store: "",
  });

  const [file, setProfilePicture] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const defaultImage = "images/img_placeholder.png";
  const [loading, setIsLoading] = useState<boolean>(false);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (editMember) {
      setFormData({
        ...formData,
        firstName: editMember.firstName,
        lastName: editMember.lastName,
        email: editMember.email,
        number: editMember.number,
        store: editMember.store,
      });
      setSelectedImage(editMember.image);
    }
  }, [editMember]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      !formData.email ||
      !formData.firstName ||
      !formData.lastName ||
      !formData.number ||
      !formData.store ||
      !selectedImage
    ) {
      return;
    }

    // Log formData for debugging
    setIsLoading(true);
    try {
      let res = await registerSalesMember({ ...formData, file: file });
      successCloseHandle();
      setIsLoading(false);
    } catch (err: any) {
      const msg = await err?.response.data?.message;
      toast.error(msg);
      setIsLoading(false);
      console.log("Error:", err);
    }
  };

  const convertToBase64 = (selectedFile: any) => {
    const reader: any = new FileReader();

    reader.readAsDataURL(selectedFile);

    reader.onload = () => {
      const base64Data = reader.result.split(",")[1];
      setProfilePicture(base64Data);
    };
  };
  //* profile change
  const handleImage = (event: any) => {
    const file = event.target?.files[0];
    if (file) {
      setSelectedImage(file);
      convertToBase64(file);
    }
  };

  const editHandler = async () => {
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.number ||
      !selectedImage
    ) {
      return;
    }
    setIsLoading(true);
    try {
      const data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        file: file ? file : null,
        id: editMember._id,
        store: formData.store,
        // lastName:formData.number,
      };
      let res = await updateProfile(data);
      successCloseHandle();
      toast.success(res?.data?.message);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="overlay">
      <div className={editMember ? "edit-employee-main" : "add-employee-main"}>
        <button className="close-btn" onClick={closeHandle}>
          <img src="images/times_icon.svg" alt="" />
        </button>
        <div className="head">
          <h3>{editMember ? "Edit Sales Member" : "Add Sales Member"}</h3>
        </div>
        <div className="add-employee-details">
          <div className="profile">
            <div className="profile-img">
              <img
                src={
                  selectedImage == null
                    ? defaultImage
                    : typeof selectedImage === "object"
                    ? URL.createObjectURL(selectedImage)
                    : selectedImage
                }
                alt=""
              />

              <button className="delete-btn">
                <img src="images/edit_icon.svg" alt="" />
                <input type="file" onChange={handleImage} />
              </button>
            </div>
            <label>Update Picture</label>
          </div>
          <div className="employee-info-fill">
            <div className="input-group">
              <div className="input-field">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </div>
              <div className="input-field">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="input-group">
              <div className="input-field">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  disabled={editMember ? true : false}
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
              <div className="input-field">
                <label>Phone Number</label>
                <input
                  type="number"
                  name="number"
                  value={formData.number}
                  disabled={editMember ? true : false}
                  onChange={handleChange}
                  placeholder="+91 1234567890"
                />
              </div>
            </div>
            <div className="input-group">
              <div className="input-field">
                <label>Store</label>
                <input
                  type="text"
                  name="store"
                  value={formData.store}
                  onChange={handleChange}
                  placeholder="Store Name"
                />
              </div>
            </div>
          </div>
        </div>
        {editMember ? (
          <div className="add-employee-btn">
            <button className="cancle-btn" onClick={closeHandle}>
              Cancel
            </button>
            <button
              className="save-btn"
              disabled={loading}
              onClick={editHandler}
            >
              {" "}
              {loading ? <Loading /> : "Save"}
            </button>
          </div>
        ) : (
          <div className="add-employee-btn">
            <button disabled={loading} onClick={handleSubmit}>
              {loading ? <Loading /> : "Add Member"}{" "}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddSaleMember;
