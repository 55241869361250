import React from 'react';

const CalculatorPermission = () => {
  return (
    <>
    <div className='loaction-list-main'>
    <div className='calculator-parent'>
    <div className="calculator-overlay">
                    <div className="permission-denied-main">
                        <button className="delete-icon">
                          <img src="images/denied_icon.svg" alt="" />
                        </button>
                        <h2>Permission Denied</h2>
                        <p>Currently you don’t have permission to access this panel</p>
                       
                      </div>
                  </div>
    </div> 
    </div>
     
    </>
  );
}

export default CalculatorPermission;
