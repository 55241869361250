import { useContext, useEffect, useState } from 'react';


import { RealmProvider, useAuth, UserProvider } from '@realm/react';

import InitialLoading from '../../components/common/InitialLoading';
import { getProfileData } from '../../config/api';
import AppContext from '../../context/AppProvider';
import { Device } from '../../models/Device';
import { SheetData } from '../../models/SheetData';
import { getToken } from '../../utils/common';
import CalculatorPermission from '../components/CalculatorPermission';
import WebDashboard from './WebDashboard';
import Header from '../../components/common/Header';



const Calculator = () => {
    
  const token = getToken()
  const [permission , setPermission] = useState(true)
  const [loading , setIsLoading] = useState(true)
  const [sheetId , setSheetId] = useState([])
  
  const { profileData  , setProfileData} = useContext(AppContext)

  const {logInWithJWT} = useAuth();

  useEffect(()=>{
    const getData =async  ()=>{
      try {
        setIsLoading(true)
        const res:any = await getProfileData()
        if(res?.status){
          setIsLoading(false)
          setProfileData(res?.data)
          if(res?.data?.permissions?.calculator?.calculator == "Yes"){
            setPermission(false)
            setSheetId(res?.data?.permissions?.calculator?.sheets)
          }
        }
        
      } catch (error) {
        setIsLoading(false)
        console.log("error" , error)
      }
    }
    getData()

  },[])



  useEffect(() => {
    if (token) {
      (async () => {
        try {
          await logInWithJWT(token);
        } catch (error) {
          console.error("Failed to log in:", error);
        }
      })();
    }
  }, [token]);

  const sheetIds = sheetId?.map((sheet:any) => sheet.sheetId);


  return (
    <>
    <Header title={"Calculator"} backButton={null} backHandle={null} admin={false}/>
        {
      loading ? <InitialLoading/> :  profileData &&  profileData?.permissions?.calculator?.calculator != "Yes" ? <CalculatorPermission/> : (<>
            <UserProvider   >
        <RealmProvider
        fallback={<InitialLoading/>}
          schema={[Device, SheetData]}
          closeOnUnmount={false}
          sync={{
            flexible: true,
            initialSubscriptions: {
              update(subs, realm) {
                subs.add(realm.objects(Device));
                if (sheetIds && sheetIds.length > 0) {
                  sheetIds.forEach((id:any) => {
                    const sheetDataCollection = realm.objects(SheetData).filtered('sheetId == $0', id);
                    subs.add(sheetDataCollection);
                  });
                }
              },
            },
           
          }}
        >
         <WebDashboard  />

         </RealmProvider>
         </UserProvider>
          </>)
        }
     
    </>
  );
}

export default Calculator;
