import React, { useEffect, useState } from "react";
import AddSaleMember from "../../components/pageComponents/AddSaleMember";
import Confirmation from "../../components/popUps/Confirmation";
import Header from "../../components/common/Header";
import { getSalesMembers, removeSaleMember } from "../../config/api";
import Loading from "../../components/common/Loading";
import { useTaskManager } from "../../hooks/useTaskManager";
import { useApp } from "@realm/react";
import { useApplication } from "../../test/useApplication";
import PageLoading from "../../components/common/PageLoading";
import Pagination from "../../components/common/Pagination";
import { getPaginationText } from "../../utils/common";
import noMember from "../../assets/noMember.svg";

const Dashboard = () => {
  const [addMember, setAddMember] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [deleteMember, setDeleteMember] = useState(false);
  const [editMember, setEditMember] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [salesMembers, setSalesMembers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageValueText, setPageValueText] = useState("");

  const { app } = useApplication();
  // async function fetchSalesMembers() {
  //   try {
  //     setIsLoading(true)
  //     let res = await getSalesMembers()
  //     setSalesMembers(res?.data?.data?.reverse() || []);
  //     setIsLoading(false)
  //   } catch (error) {
  //     setIsLoading(false)
  //     console.log("Error fetching sales members:", error);
  //   }
  // }

  const itemsPerPage = 10;

  async function fetchSalesMembers() {
    try {
      setIsLoading(true);
      if (app.currentUser) {
        // Access the MongoDB service and collection
        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const collection: any = mongodb.db("TbvRepAssist").collection("user");

        const query = { role: { $ne: "admin" } };
        const res: any = await collection.find(query);

        let filteredResults = res;

        if (searchQuery) {
          const lowerCaseQuery = searchQuery.toLowerCase();
          filteredResults = res.filter(
            (item: any) =>
              item.firstName.toLowerCase().includes(lowerCaseQuery) ||
              item.lastName.toLowerCase().includes(lowerCaseQuery) ||
              item.email.toLowerCase().includes(lowerCaseQuery) ||
              item.number.toLowerCase().includes(lowerCaseQuery)
          );
        }

        const count = filteredResults.length;
        setSalesMembers(
          filteredResults
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .reverse()
        );
        setTotalPages(Math.ceil(count / itemsPerPage));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error fetching sales members:", error);
    }
  }

  const pageHandler = (e: any) => {
    const page = e.selected + 1;
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchSalesMembers();
  }, [searchQuery, currentPage]);

  const closeHandle = () => {
    setEditMember(null);
    setAddMember(false);
  };
  const successCloseHandle = () => {
    fetchSalesMembers();
    setEditMember(null);
    setAddMember(false);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      const id = event.target.id;
      if (!id) {
        setOpenAction(false);
      }
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const memberDeleteHandler = () => {
    setDeleteMember(true);
  };

  const deleteCancel = () => {
    setDeleteMember(false);
  };

  const deleteSubmitHandle = async () => {
    setDeleteLoading(true);
    try {
      let res = await removeSaleMember(selectedId);
      if (res?.data?.status) {
        setDeleteLoading(false);

        setDeleteMember(false);
        fetchSalesMembers();
      }
    } catch (error) {
      setDeleteLoading(false);
      console.log("error", error);
    }
  };

  const memberEdit = (memberDetails: any) => {
    setEditMember(memberDetails);
    setAddMember(true);
  };

  const actionHandler = (id: string) => {
    setSelectedId(id);
    setOpenAction(!openAction);
  };

  useEffect(() => {
    const listValue = getPaginationText(
      currentPage,
      itemsPerPage,
      salesMembers?.length
    );
    setPageValueText(listValue);
  }, [currentPage, itemsPerPage, totalPages, salesMembers]);
  return (
    <>
      {addMember && (
        <AddSaleMember
          closeHandle={closeHandle}
          editMember={editMember}
          successCloseHandle={successCloseHandle}
        />
      )}
      {deleteMember && (
        <Confirmation
          title={"Are you sure, you want to delete?"}
          isLoading={deleteLoading}
          cancelHandle={deleteCancel}
          submitHandle={deleteSubmitHandle}
          btnTitle={"Delete"}
        />
      )}

      <Header title="Sales member" backButton={null} backHandle={null}  admin={true}/>
      <div className="loaction-list-main">
        <div className="loaction-list-head">
          <div className="search-bar">
            <input
              type="search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by title"
            />
            <img src="images/search_icon.svg" alt="" />
          </div>
          <button className="add-service" onClick={() => setAddMember(true)}>
            + Add Member
          </button>
        </div>
        <div className="table-data-main" style={{ padding: "0" }}>
          {loading ? (
            <PageLoading />
          ) : salesMembers?.length == 0 ? (
            <div
              style={{
                display: "flex",
                flexWrap:"wrap",
                justifyContent: "center",
                height: "60vh",
                alignItems: "center",
                textAlign:"center",
                alignContent:"center",
                marginTop: 20,
              }}
            >
              <img src={noMember} />
              <h2 style={{width:"100%" ,marginTop:"15px" , fontSize:"22px", lineHeight:"normal", height:"fit-content"}}>No Member Added Yet!</h2>
            </div>
          ) : (
            <div
              className="table-data"
              style={{ height: "59vh", overflowY: "auto" }}
            >
              {
                <table border={0} cellPadding={0} style={{ width: "100%" }}>
                  <thead
                    style={{
                      backgroundColor: "#FDFDFD",
                      borderBottom: "1px solid #ededed",
                      borderTop: "1px solid #ededed",
                    }}
                  >
                    <tr>
                      <th className="heading">Name</th>
                      <th className="heading">Email</th>
                      <th className="heading">Phone Number</th>
                      <th className="heading" style={{ width: "7%" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesMembers?.map((value: any) => {
                      return (
                        <React.Fragment key={value?._id}>
                          {value?.role != "admin" && (
                            <tr style={{ borderBottom: "1px solid #ededed" }}>
                              <td style={{ padding: "15px 25px" }}>
                                <table>
                                  <tr>
                                    <td
                                      className="product-img"
                                      style={{ border: "none" }}
                                    >
                                      <a href="#">
                                        <img
                                          src={value?.image}
                                          alt="pipes image"
                                        />
                                      </a>
                                    </td>
                                    <td
                                      className="rating"
                                      style={{
                                        border: "none",
                                        padding: "0 20px",
                                      }}
                                    >
                                      {value?.firstName} {value?.lastName}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td className="table-col">{value?.email}</td>
                              <td className="table-col">{value?.number}</td>

                              <td
                                style={{ textAlign: "center" }}
                                className="table-col"
                              >
                                <button
                                  id={value?._id}
                                  className="action-btn"
                                  onClick={() => actionHandler(value?._id)}
                                >
                                  <img
                                    id={value?._id}
                                    src="images/action_icon.svg"
                                    alt=""
                                  />
                                </button>
                                {selectedId == value?._id && openAction && (
                                  <div className="action-main">
                                    <ul>
                                      <li>
                                        <a
                                        style={{cursor:"pointer"}}
                                          className="edit-btn"
                                          onClick={() => memberEdit(value)}
                                        >
                                          <span>
                                            <img
                                              src="images/action_edit_icon.svg"
                                              alt=""
                                            />
                                          </span>
                                          Edit
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                         style={{cursor:"pointer"}}
                                          className="block-btn"
                                          onClick={memberDeleteHandler}
                                        >
                                          <span>
                                            <img
                                              src="images/action_delete_icon.svg"
                                              alt=""
                                            />
                                          </span>
                                          Delete
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              }
            </div>
          )}
        </div>
      </div>
      {salesMembers?.length != 0 && (
        <>
          <div className="next-previous-slide">
            <div className="slides-heading">
              <span>{pageValueText}</span>
            </div>
            <div className="slides-button">
              {/* <Pagination
                handlePageChange={pageHandler}
                pageCount={totalPages}
              /> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
