import React, { useState } from 'react';
import MyIncidents from './MyIncidents';
import NewIncidents from '../components/NewIncidents';
import Header from '../../components/common/Header';
import IncidentView from './IncidentView';

const IncidentMain = () => {
  const [tab, setTab] = useState("myIncidents")
  const [newIncident, setNewIncident] = useState(false)
  const [viewData, setViewData] = useState(null)

  const closeHandle = () => {
    setNewIncident(false)
  }


  return (
    <>
      <Header title={`Incident${tab == "myIncidents" ? "" : "s"}`} backButton={viewData || newIncident} backHandle={() => {
        setViewData(null)
        setNewIncident(false)
      }} admin={false} />
      {
        newIncident ? <NewIncidents closeHandle={closeHandle} editData={null} /> : viewData ? <IncidentView viewData={viewData} /> : (
          <>
            <div className="loaction-list-main" style={{ verticalAlign: "top", borderBottomRightRadius: "unset", borderBottomLeftRadius: "unset", borderBottom: "none" }}>
              <div className="loaction-list-head incident-select-head">
                <ul className="incident-select">
                  <li className={tab == "myIncidents" ? "active" : ""} onClick={() => setTab("myIncidents")} >My Incidents</li>
                  <li className={tab == "allIncidents" ? "active" : ""} onClick={() => setTab("allIncidents")}>All Incidents</li>
                </ul>
                <button className="add-service" onClick={() => setNewIncident(true)}>+ Add Incident</button>
              </div>
            </div>
            {tab == "myIncidents" && <MyIncidents setViewData={setViewData} setNewIncident={setNewIncident} type={"myself"} />}
            {tab == "allIncidents" && <MyIncidents setViewData={setViewData} setNewIncident={setNewIncident} type={""} />}

          </>
        )
      }

    </>
  );
}

export default IncidentMain;
