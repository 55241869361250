import React, { useEffect, useState } from 'react';
import { addPermission, addUser, getSheets } from '../../config/api';
import toast from 'react-hot-toast';
import Loading from './Loading';


const AddUser = ({ closeHandle , viewData , setAddUser , setViewData }: any) => {
    const [selectedImage, setSelectedImage] = useState<any>()
    const [dealer, setDealer] = useState("Select")
    const [positionShortName, setPositionShortName] = useState("")
    const [position, setPosition] = useState(false)
    const [permission, setPermission] = useState({
        calculator: "",
        incidentExport: "",
        sheetNumber: ""
    })

    const [sheetNumber, setSheetNumber] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [sheetIds, setSheetIds] = useState<any[]>([])


    useEffect(() => {
        (async () => {
            try {
                const res: any = await getSheets()
                if (res?.status) {
                    setSheetNumber(res?.data)
                }

            } catch (error) {
                console.log("error", error)
            }

        })()
    }, [])
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        storeName: "",
        storeZipCode: "",
        tspId: "",
        
    })

    useEffect(() => {
        if(viewData){
          setPermission({
            ...permission , 
              "calculator":viewData?.permissions?.calculator?.calculator ,
              "incidentExport":viewData.permissions?.incidentExport ,
            //   "sheetNumber":viewData?.permissions?.calculator?.sheetNumber
          })
          setSheetIds(viewData?.permissions?.calculator?.sheets || [])
          setData({
            ...data ,  firstName: viewData?.firstName,
            lastName: viewData?.lastName ,
            email: viewData?.email ,
            phoneNumber: viewData?.number ,
            storeName: viewData?.storeName ,
            storeZipCode: viewData?.zipCode,
            tspId: viewData?.tspId
          })
          setDealer(viewData.position)
          setPositionShortName(viewData.positionShortName)
          setSelectedImage(viewData?.image)
        }
       
      }, [viewData])

   

    //* profile change 
    const handleImage = (event: any) => {
        const file = event.target?.files[0]
        if (file) {
            setSelectedImage(file)

        }
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        if (name == "phoneNumber") {
            if (value.length > 10) return
        }

        setData({
            ...data, [name]: value
        })

    }

    const handleSelect = (value: string, shortName: string) => {
        setDealer(value)
        setPositionShortName(shortName)
        setPosition(false)
    }


    const roles = [
        { title: "Dealer/Owner", abbreviation: "DLR" },
        { title: "District Manager", abbreviation: "DM" },
        { title: "Retail Store Manager", abbreviation: "RSM" },
        { title: "Retail Store Assistant Manager", abbreviation: "RAM" },
        { title: "Retail Store Associate", abbreviation: "RSA" },
        { title: "Operations", abbreviation: "OPR" }
    ];

    const handleCalculator = (value: string) => {

        // setPermission({...permission , "sheetNumber":""})
        setSheetIds([])
        if (permission.calculator == value) {
            setPermission({ ...permission, "calculator": "", "sheetNumber": "" })

        } else {

            setPermission({ ...permission, "calculator": value, "sheetNumber": "" })
        }
    }

    const handleIssueExport = (value: string) => {
        if (value == permission.incidentExport) {
            setPermission({ ...permission, "incidentExport": "" })
        } else {
            setPermission({ ...permission, "incidentExport": value })
        }

    }

   
    const handleSheetName = (value: any) => {
        const isIdPresent = sheetIds.find((item)=> item?.sheetId == value?.sheetId )
        if(isIdPresent){
           const filterIds = sheetIds.filter((item:any)=>{
            return item?.sheetId !== value?.sheetId

           }) 
           setSheetIds(filterIds)

        }else{
            setPermission({ ...permission, "sheetNumber": value?.name })
            const obj = {
                sheetNumber:value?.name,
                sheetId:value?.sheetId
            }
            setSheetIds([...sheetIds , obj])
        }
       
        // if (value == permission?.sheetNumber) {
        //     setPermission({ ...permission, "sheetNumber": "" })
        //     setSheetIds([])
        // } else {
        //     setPermission({ ...permission, "sheetNumber": value?.name })
        //     setSheetIds([value?.sheetId])
        // }
    }

    const handleSubmit = async ()=>{
        for (let key in data) {
            if (data[key as keyof typeof data] === "") {
              return toast.error("All fields required")
            }
          }
          if(dealer == "Select"){
            return toast.error("All fields required")
          }

          if(permission.calculator == "Yes" && sheetIds.length == 0){
            return toast.error("SheetNumber is required!")
      
          }
         
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const isValid = emailRegex.test(data.email)


    if (!isValid) {
      return toast.error("Please enter valid email!")
    }


          const formData = new FormData()
          formData.append("firstName" , data.firstName)
          formData.append("lastName" , data.lastName)
          formData.append("phoneNumber" , data.phoneNumber)
          formData.append("email" , data.email)
          formData.append("position" , dealer)
          formData.append("tspId" , data.tspId)
          formData.append("storeName" , data.storeName)
          formData.append("positionShortName" , positionShortName)
          formData.append("storeZipCode" , data.storeZipCode)
          formData.append("calculator" , permission.calculator)
          formData.append("incidentExport" , permission.incidentExport)
        //   formData.append("sheetNumber" , permission.sheetNumber)

          if(viewData){
            if(typeof selectedImage === "object"){
                formData.append("newCustomerImage" , selectedImage) 
            }else{
                formData.append("oldImageUrl" , selectedImage)
            }
          }else{
            formData.append("profileImage" , selectedImage)

          }
         
            formData.append("sheets" , JSON.stringify(sheetIds))

        //   if(sheetIds.length > 0 ){
        //     sheetIds.forEach((item)=>{
        //         formData.append("sheetIds" , item)
        //       })
    
        //   }

        
          try {
            setLoading(true)
            if(viewData){
                const res:any = await addPermission(formData , viewData?.id)
            }else{
                let res = await addUser(formData)
            }
            setLoading(false)
            closeHandle()
            
          } catch (error:any) {
            setLoading(false)
            toast.error(error?.message)
          }

    }

    const defaultImage = "images/img_placeholder.png";
    return (
        <>
            <div className="add-employe-overlay">
                <div className="add-employee-main">
                    <button className="close-btn" onClick={() =>{
                        setViewData(null)
                        setAddUser(false)
                    }}><img src="images/times_icon.svg" alt="" /></button>
                    <div className="head">
                        <h3>{viewData ? "Edit" : "Add"} User</h3>
                    </div>
                    <div className="add-employee-details">
                        <div className="profile">
                            <div className="profile-img">
                                <img
                                    src={
                                        (selectedImage == null || selectedImage == "")
                                            ? defaultImage
                                            : typeof selectedImage === "object"
                                                ? URL.createObjectURL(selectedImage)
                                                : selectedImage
                                    }
                                    alt=""
                                />
                                <button className="delete-btn"><img src="images/edit_icon.svg" alt="" />
                                    <input type="file" onChange={handleImage} />
                                </button>
                            </div>
                            <label>Update Picture</label>
                        </div>
                        <div className="employee-info-fill">
                            <div className="input-group">
                                <div className="input-field">
                                    <label>First Name</label>
                                    <input type="text" placeholder="First Name" name="firstName" onChange={handleChange} value={data.firstName} />
                                </div>
                                <div className="input-field">
                                    <label>Last Name</label>
                                    <input type="text" placeholder="Last Name" name='lastName' onChange={handleChange} value={data.lastName} />
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-field">
                                    <label>Position</label>
                                    <div style={{position:"relative"}}>
                                    <button onClick={() => setPosition(!position)}>{dealer} <img src="images/angle_down_grey.svg" /></button>
                                    {/* <span className="icon"><img src="images/new_user_icon.svg" alt="" /></span> */}
                                    {
                                        position && (
                                            <div className="dropdown-list-main">
                                                <ul>
                                                    {roles.map((value) => (
                                                        <li key={value.abbreviation} onClick={() => handleSelect(value.title, value.abbreviation)}>
                                                            <span>{value.title}</span>
                                                            <span>{value.abbreviation}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                                <div className="input-field">
                                    <label>Email</label>
                                    <input type="mail" placeholder="abc@gmail.com" name="email" onChange={handleChange} value={data.email} />
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-field">
                                    <label>Phone Number</label>
                                    <input type="number" placeholder="Mobile Number" name="phoneNumber" onChange={handleChange} value={data.phoneNumber} />
                                </div>
                                <div className="input-field">
                                    <label>Store</label>
                                    <input type="text" placeholder="Store Name" name="storeName" onChange={handleChange} value={data.storeName} />
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-field">
                                    <label>Store Zip Code</label>
                                    <input type="text" placeholder="Store Zip Code" name="storeZipCode" onChange={handleChange} value={data.storeZipCode} />
                                </div>
                                <div className="input-field">
                                    <label>TSP ID</label>
                                    <input type="text" placeholder="TSP ID" name="tspId" onChange={handleChange} value={data.tspId} />
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-field">
                                    <label>Calculator</label>
                                    <div className='calc-select'>
                                        <button className={permission?.calculator == "Yes" ? "active" : ""} onClick={() => handleCalculator("Yes")}>Yes</button>
                                        <button className={permission?.calculator == "No" ? "active" : ""} onClick={() => handleCalculator("No")}>No</button>
                                    </div>
                                </div>
                                <div className="input-field">
                                    <label>Incident Export</label>
                                    <div className='calc-select'>
                                        <button className={permission?.incidentExport == "Yes" ? "active" : ""} onClick={() => handleIssueExport("Yes")}>Yes</button>
                                        <button className={permission?.incidentExport == "No" ? "active" : ""} onClick={() => handleIssueExport("No")}>No</button>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group">
                                {
                                    permission?.calculator == "Yes" && <div className="input-field">
                                        <label>Sheet number</label>
                                        <div className='sheet-select'>
                                            {
                                                sheetNumber && sheetNumber?.map((value: any) => {
                                                    const isActive = sheetIds.some((item) => item.sheetId === value?.sheetId);

                                                    return (
                                                        <>
                                                            <button onClick={() => handleSheetName(value)} className={isActive ? "active" : ""}>{value?.name}</button>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                }


                            </div>

                        </div>
                    </div>
                    <div className="add-employee-btn">
                        <button className='cancle-btn' onClick={()=>{
                            setAddUser(false)
                            setViewData(null)
                        }}>Cancel</button>
                        <button className='add-btn' onClick={handleSubmit} disabled={loading}>{loading ? <Loading /> : viewData ? "Update" : "Add"}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddUser;
