import Realm, { ObjectSchema } from 'realm';

// class SheetDatumItem extends Realm.Object {
//   actFee!: string;
//   autoPay!: string;
//   devPrice!: string;
//   device!: string;
//   idVeriff!: string;
//   joinedText!: string;
//   monthly!: string;
//   notes!: string;
//   phoneLineCount!: string;
//   plan!: string;
//   portIn!: string;
//   position!: string;
//   today!: string;
//   type!: string;

//   static schema: ObjectSchema = {
//     name: 'sheetDatum_items',
//     embedded: true, // This marks the object as embedded
//     properties: {
//       actFee: 'string?',
//       autoPay: 'string?',
//       devPrice: 'string?',
//       device: 'string?',
//       idVeriff: 'string?',
//       joinedText: 'string?',
//       monthly: 'string?',
//       notes: 'string?',
//       phoneLineCount: 'string?',
//       plan: 'string?',
//       portIn: 'string?',
//       position: 'string?',
//       today: 'string?',
//       type: 'string?',
//     },
//   };
// }

class SheetData extends Realm.Object {
  _id!: string;
  // items!: SheetDatumItem[];

  static schema: ObjectSchema = {
    name: 'sheetDatum',
    properties: {
      _id: 'objectId',
      fullText: 'string?',
      sheetId: { type: 'string', indexed: true , optional:true },
      // items: 'sheetDatum_items[]',
    },
   
    primaryKey: '_id',
  };
}

export { SheetData };
