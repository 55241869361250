import React from 'react';
import { Loader } from 'rsuite';

const PageLoading = () => {
  return (
    <div style={{display:"flex" , justifyContent:"center"  , alignItems:"center" , height:"66vh"}}>
    <Loader size={"md"}/>
    </div>
  );
}

export default PageLoading;
