import { useState } from "react"
import { verifyEmail } from "../../config/api"
import Loading from "../common/Loading"
import toast from "react-hot-toast"

const ConfirmEmail = ({ email , setEmail , backHandler, handleForgot }: {email:string , setEmail:any , backHandler: (a: string) => void, handleForgot: (a: string) => void }) => {


  const [loading, setIsLoading] = useState(false)

  const submitHandle = async () => {
    if (!email) return

    try {
      setIsLoading(true)
      let res:any = await verifyEmail({ email })
      if(res?.status){
        setIsLoading(false)
        toast.success(res?.message)
        handleForgot('VerifyOtp')
      }
     
    } catch (error:any) {
      toast.error(error?.response?.data?.message)
      setIsLoading(false)
      console.log("error")
    }

  }
  return (
    <>
      <div className="login-back-btn" onClick={() => backHandler("login")}>
        <a ><img src="images/left_arrow.svg" alt="" /></a>
      </div>
      <div className="logo">
        <a ><img src="images/Logo.png" alt="Logo" /></a>
      </div>
      <div className="login-input-details">
        <h2>Forgot Password?</h2>
        <p>Please enter your registered email id</p>
        <div className="input-group">
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="youremail@example.com" />
          <span className="icon"><img src="images/mail_icon.svg" alt="" /></span>
        </div>
        <div className="login-btn forgot-btn">
          <button disabled={loading || !email} onClick={submitHandle}>{loading ? <Loading /> : "SUBMIT"} </button>
        </div>
      </div>
    </>
  );
}

export default ConfirmEmail;
