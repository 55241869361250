
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useRealm, useUser } from '@realm/react';

import { Device } from '../models/Device';
import { SheetData } from '../models/SheetData';


export function useTaskManager() {


  
  const sheetValues = useQuery(SheetData);
  const allDevice = useQuery(Device);


  const sheets = sheetValues
  const devices = allDevice

  return {
    sheets,
    devices,
  };
}
