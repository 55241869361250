

const Illustration = ({imgLink , title}:{imgLink:any , title:any}) => {
    return (
      <div style={{display:"flex" , justifyContent:"center" ,flexDirection:"column"  , alignItems:"center" , transform:"scale(0.4)", height:"65vh"}}>
          <img style={{width:"275px"}} src={imgLink} />
          <h2 style={{marginTop:"20px"}}>{title}</h2>
      </div>
    )
  }
  
  export default Illustration