import React, { useState } from 'react';
import ReactModal from 'react-modal';
import './ImageModal.css'; 

ReactModal.setAppElement('#root');

const ImageModal = ({ imageUrl, isOpen, onRequestClose }:any) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          border: 'none',
          borderRadius: 0,
        },
      }}
    >
      <div className="image-modal-content" onClick={onRequestClose}>
        <img src={imageUrl} alt="Full Screen" className="full-screen-image" />
      </div>
    </ReactModal>
  );
};

export default ImageModal;
