import React, { useEffect, useMemo, useState } from 'react';
import { useTaskManager } from '../hooks/useTaskManager';
import error from "../assets/error.svg";
import { apply_row_filters } from "../pkg/demo_wasm"



const TableRow = React.memo(({ id, resultId, getResultValue, dropDownAction, setDropDownAction, dropDown, setDropDown }: any) => {


  let demoFilterValue = {
    deviceName: "",
    portIn: "",
    idVeriff: "",
    autoPay: "",
    selectedType: "",
    selectedLine: "",
    selectedPlan: ""
  }

  let resultValue = {
    devicePrice: "",
    planToday: "",
    planMonthly: "",
    actFee: "",
    notes: "",
    multipleMatches: false

  }

  const { devices } = useTaskManager();
  const yesNo = ["", "Yes", "No"]

  const [searchQuery, setSearchQuery] = useState("")
  const [deviceData, setDeviceData] = useState<any>([])
  const [filterValue, setFilterValue] = useState(demoFilterValue)
  const [result, setResult] = useState<any>(resultValue)




  useEffect(() => {
    setDeviceData(devices)
  }, [devices])




  useEffect(() => {

    let value = 0
    const planDevicePrice = parseFloat(result?.devicePrice?.replace(/[^0-9.-]+/g, ''));
    const planTodayValue = parseFloat(result?.planToday?.replace(/[^0-9.-]+/g, ''));
    const actFee = parseFloat(result?.actFee?.replace(/[^0-9.-]+/g, ''));

    value += (isNaN(planTodayValue) ? 0 : planTodayValue) + (isNaN(planDevicePrice) ? 0 : planDevicePrice) + (isNaN(actFee) ? 0 : actFee)


    let planValue = 0
    const planMonthlyValue = parseFloat(result?.planMonthly?.replace(/[^0-9.-]+/g, ''));
    planValue += (isNaN(planMonthlyValue) ? 0 : planMonthlyValue);

    const resultCopy = {
      "id": id,
      "planValue": Number(value.toFixed(2)),
      "monthlyValue": Number(planValue.toFixed(2))
    }
    getResultValue(resultCopy)
  }, [result])


  const handelDropDown = (value: any) => {

    setDropDownAction(!dropDownAction)
    setDropDown(value)
  }

  const emptyResultHandler = () => {
    setResult(resultValue)
  }


  useEffect(() => {
    function handleClickOutside(event: any) {

      const id = event.target.id
      if (!id) {
        setDropDownAction(false);
      }
    }
    document.addEventListener('click', handleClickOutside);

    return () => {

      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  const sortedDevices = deviceData?.length > 0 ? [...deviceData].sort((a: any, b: any) => a._id - b._id) : [];




  const dropDownValue = (argValue: any) => {

    setSearchQuery("")
    setDeviceData(devices)

    if (argValue) {
      setFilterValue({ ...filterValue, "deviceName": argValue })
    }

    setDropDownAction(false)
  }
  function matchOrMaxValue(itemValue: any, filterValueArgument: any): boolean {
    // Handle exact match or "any" value
    if (itemValue === filterValueArgument || itemValue.toLowerCase() === "any") {
      return true;
    }

    // Handle range in itemValue (e.g., "2-10")
    if (itemValue.includes('-')) {
      const [min, max] = itemValue.split('-').map(Number);
      const numFilterValue = Number(filterValueArgument);

      // Check if filterValue lies within the range
      if (numFilterValue >= min && numFilterValue <= max) {
        return true;
      }
    }

    return false;
  }

  

  useEffect(() => {
    emptyResultHandler()
    if (filterValue.deviceName) {
      const portValue = filterValue.portIn || "(blank)";
      const idVeriffValue = filterValue.idVeriff || "(blank)";
      try {
        const matchedRecord = apply_row_filters(id, filterValue.deviceName, portValue, idVeriffValue)
        const seriesValue = matchedRecord.split(",,,")
        setResult({
          devicePrice: seriesValue[9],
          planToday: seriesValue[10],
          planMonthly: seriesValue[11],
          actFee: seriesValue[12],
          notes: seriesValue[13],
          multipleMatches: Number(seriesValue[0]) > 1 ? true : false
        })
      } catch (error) {
        console.log("error" ,error)
      }
     
    }

  }, [filterValue, resultId])


  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchQuery(value);
    const actualValue = value.toLowerCase();

    const filterValue = devices.filter((item: any) => {
      const deviceName = item.device.toLowerCase();
      return deviceName.includes(actualValue);
    });

    setDeviceData(filterValue);

  }

  const portValue = (argValue: string) => {
    setFilterValue({ ...filterValue, "portIn": argValue })

  }

  const idVerification = (argValue: string) => {

    setFilterValue({ ...filterValue, "idVeriff": argValue })



  }

  return (
    <>
      <tr  >
        <td >
          <div className="device">
            <button id={id} style={{ fontSize: "14px" }} onClick={() => handelDropDown(id)}>
              {filterValue.deviceName || "Select"}<img src="images/angle_down.svg" alt="" />
            </button>
            {
              dropDownAction && dropDown == id && <div className="dropdown-model">
                <ul id={id}>
                  <li id={id} style={{ height: 30, fontSize: "14px" }} onClick={() => dropDownValue("")} className="select-device">
                    {filterValue.deviceName || "Select"}
                  </li>
                  <li id={id}  ><div id={id} className="searchBar">
                    <input id={id} placeholder="Search here" type="text" value={searchQuery} onChange={handleSearchChange} /> <img src="images/search.svg" alt="" />
                  </div>
                  </li>
                  {deviceData?.length === 0 ? (
                    <li className="select-device">
                      No device found
                    </li>
                  ) : (
                    <>
                      {sortedDevices?.map((item: { _id: number, device: string }) => (
                        <li

                          key={item._id}
                          onClick={() => dropDownValue(item?.device)}
                          className="select-device"
                        >
                          {item?.device}
                        </li>
                      ))}
                    </>
                  )}


                </ul>
              </div>
            }
          </div>
        </td>
        <td className="table-col web-table-col">
          <ul className="yes-no">
            {
              yesNo?.map((item: any, index: any) => {
                return (
                  <li key={index} onClick={() => portValue(item)} className={item === filterValue.portIn ? "blank active" : ""}>{item}</li>
                )
              })
            }

          </ul>
        </td>
        <td className="table-col web-table-col">

          <ul className="yes-no">
            {
              yesNo?.map((item: any, index: any) => {
                return (
                  <li key={index} onClick={() => idVerification(item)} className={item === filterValue.idVeriff ? "blank active" : ""}>{item}</li>
                )
              })
            }


          </ul>
        </td>
        <td className="table-col web-table-col">
          {result.devicePrice ? "" + result.devicePrice : ""}</td>
        <td className="table-col web-table-col">
          {result.planToday ? "" + result.planToday : ""}</td>
        <td className="table-col web-table-col">
          {result.planMonthly ? "" + result.planMonthly : ""}</td>
        <td className="table-col web-table-col">
          {result.actFee ? "" + result.actFee : ""}</td>
        <td className="table-col web-table-col">
          {result.notes ? "" + result.notes : ""} </td>
        {
          result.multipleMatches && <td className="table-col web-table-col">
            <img src={error} alt="error of multiple record " />
          </td>
        }

      </tr>
    </>
  );
});


export default TableRow;
